<app-header2></app-header2>
<!-- Banner sec -->

<section class="banner-slider">
    <div class="">
        <div class="banner-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div>
                            <div class="about-text-wrapper">
                                <h2 class="text-custom-white title" [innerHTML]="'Modos de contacto'|translate|uppercase|nl2pbr"></h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="gradient">

    </div>
    <div style="position: absolute; bottom: 10px; ">
        <div class="row pl-4 pr-4">
            <div class="col-md-5">
            </div>
            <div class="text-center col-md-2">
                <a class="btn-second orange-btn wow fadeInUp m-1" href="javascript:void(0)">↓</a>
            </div>
            <div class="col-md-5 ">
                <a class="btn-second orange-btn wow fadeInUp m-1 btn-fixed-2" href="#">...</a>
            </div>
        </div>
    </div>
</section>

<section class=" news-sec-desktop bg-light-theme d-md-block">
    <div class="container-fluid  backgroud-w p-relative shadow-top" style="background: #FFF;">
        <div class="container">
            <div class="row ml-1">
                <div class="col-sm-12 col-md-4 mt-5 mb-5">
                    <h3 class="text-custom-black " [innerHTML]="'¿Cómo podemos ayudarte?'|translate|uppercase|nl2pbr"></h3>
                    <p>{{'Rellena el siguiente formulario con tu consulta y te responderemos lo antes posible'|translate}}</p>
                    <div *ngIf="exito" class="alert alert-success">
                        <p>Gracias por su interés en Nerva EXE, contactaremos con usted lo antes posible.</p>
                    </div>
                    <div  *ngIf="!exito" class="mt-5">
                        <form [formGroup]="contactoForm" (ngSubmit)="enviarConsulta()">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>MOTIVO DE LA CONSULTA</label>
                                        <input [ngClass]="{'error' : submitted && f.motivo.errors?.required}" type="text" class="form-control" placeholder="Escribe tu motivo" formControlName="motivo" required>
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>TU CONSULTA</label>
                                        <textarea class="form-control" [ngClass]="{'error' : submitted && f.consulta.errors?.required}" placeholder="Redacta tu consulta" formControlName="consulta" required></textarea>
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>NOMBRE</label>
                                        <input type="text" [ngClass]="{'error' : submitted && f.nombre.errors?.required}" class="form-control" placeholder="Escribe tu nombre" formControlName="nombre" required>
                                    </div>

                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>APELLIDOS</label>
                                        <input type="text" [ngClass]="{'error' : submitted && f.apellidos.errors?.required}" class="form-control" placeholder="Escribe tus apellidos" formControlName="apellidos" required>
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>PERFIL</label>
                                        <select formControlName="profesional" >
                                            <option selected="selected" value="0">Usuario Final</option>
                                            <option value="1">Profesional / Concesionario</option>
                                        </select>
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>CORREO ELECTRÓNICO</label>
                                        <input type="email" class="form-control" [ngClass]="{'error' : submitted && f.email.errors?.email || submitted && f.email.errors?.required}" placeholder="Escribe tu correo electrónico" formControlName="email" required>
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <!--<div class="col-md-6">
                                    <div class="form-group">
                                        <label>NÚMERO DE TELÉFONO</label>
                                        <select >
                                            <option>Prefijo</option>
                                        </select>
                                    </div>
                                </div>-->
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>NÚMERO DE TELÉFONO</label>
                                        <input type="text" class="form-control" [ngClass]="{'error' : submitted && f.telefono.errors?.required}"  placeholder="Escribe tu teléfono" formControlName="telefono" required>
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>PAÍS</label>
                                        <select formControlName="pais" required [ngClass]="{'error' : submitted && f.pais.errors?.required}">
                                            <option>Selecciona el país donde resides</option>
                                            <option value="Spain">España</option>
                                        </select>
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>CÓDIGO POSTAL</label>
                                        <input type="text" class="form-control" [ngClass]="{'error' : submitted && f.cp.errors?.required}" placeholder="Escribe tu código postal" formControlName="cp" required>
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>Al seleccionar "ENVIAR CONSULTA", acepto que se pongan en contacto conmigo para resolver mi consulta.<br/>Enviando el formulario declaro haber leído y aceptado la información de protección de datos.</p>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-default btn-black" [disabled]="loading"><span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> <span>ENVIAR CONSULTA</span></button>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-sm-12 col-md-2 mt-5 mb-5"></div>
                <div class="col-sm-12 col-md-6 mt-5 mb-5">
                    <h3 class="text-custom-black " [innerHTML]="'Red de concesionarios Nerva'|translate|uppercase|nl2pbr"></h3>
                    <p>{{'Encuentra tu punto de venta y servicio oficial'|translate}}</p>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group"  [formGroup]="contactoForm">
                                <select (change)="filtrar()"  formControlName="provincia">
                                    <option  *ngIf="isLoading" >Cargando concesionarios ...</option>
                                    <option value="" *ngIf="!isLoading">Selecciona tu provincia</option>
                                    <option *ngFor="let provincia of provincias" value="{{ provincia }}">{{ provincia }}</option>
                                </select>
                            </div>

                        </div>
                    </div>
                    <div class="row mt-5">
                        
                        <div class="col-md-12 block-concesionario" *ngIf="isLoading">
                            Cargando concesionarios ...
                        </div>
                        <div class="col-md-12 block-concesionario" style="margin: 5px;" *ngFor="let concesionario of concesionarios">
                            <h4>{{ concesionario.address_title }} </h4>
                            {{ concesionario.address_line1 }}. {{ concesionario.city }}. {{ concesionario.pincode }}. {{ concesionario.country }}<br/>
                            {{ concesionario.address_line2 }}<br *ngIf="concesionario.address_line2"/>
                            {{ concesionario.horario }}<br/> 
                             <a  *ngIf="concesionario.web" target="_blank" style="color: #000; text-decoration: underline" href="{{ concesionario.web }}">{{ concesionario.web }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</section>
<app-footer2></app-footer2>