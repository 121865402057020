<app-header2></app-header2>
<!-- Banner sec -->



<section class="banner-slider">
    <div class="">
        <div class="banner-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div>
                            <div class="about-text-wrapper">
                                <h2 style="font-size: 50px;" class="text-custom-white title" [innerHTML]="'UNA MOVILIAD CADA VEZ MÁS CIRCULAR'|translate|nl2pbr"></h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <app-test-drive-button></app-test-drive-button>
    </div>
    <div class="gradient">

    </div>
    <div style="position: absolute; bottom: 10px; width: 100%; ">
        <div class="row pl-4 pr-4">
            <div class="col-md-5">
            </div>
            <div class="text-center col-md-2">
                <a class="btn-second flecha orange-btn wow fadeInUp m-1" (click)="goDown()" href="javascript:void(0);">↓</a>
            </div>
            <div class="col-md-5 ">
                <a class="btn-second orange-btn wow fadeInUp m-1 btn-fixed-2" href="#">...</a>
            </div>
        </div>
    </div>
</section>

<section class=" news-sec-desktop bg-light-theme d-md-block ">
    <div class="container-fluid  backgroud-w p-relative shadow-top ">

        <div class="row ml-1">
            <div class="col-md-12 mt-10-rem">
                <p>{{'NUESTRO COMPROMISO'|translate}}</p>

                <h2 class="title  text-custom-black " [innerHTML]="'CIRCULARIDAD COMO PRINCIPIO DE DISEÑO'|translate|nl2pbr"></h2>

            </div>
        </div>
        <div class="row ml-1 mt-3-rem mb-3">
            <div class="col-md-12">
                <p>{{'Nerva contribuye a la transición hacia una economía circular regenerativa, que se basa en dos pilares:'|translate}}</p>
            </div>
        </div>
        <div class="row ml-1 mt-3-rem  economia">
            <div class="col-md-3 d-none">
            </div>

            <div class="col-md-3 mb-3">
                <div class="uno">
                    <img src="assets/images/economiaCircular/loop.png" />
                    <br>
                    <br>
                    <p>{{'Un modelo colaborativo pensado bajo una mentalidad Cradle to Cradle (“de la cuna a la cuna”), en el que la circularidad ha estado presente desde la fase más temprana de diseño.'|translate}}</p>
                </div>
            </div>
            <div class="col-md-3 mb-3">
                <div class="dos">
                    <img src="assets/images/economiaCircular/diagram.png" />
                    <br>
                    <br>
                    <p>{{'Una apuesta por las energías renovables, la ganancia circular y el pensamiento sistémico que fomenta el triple impacto (social, económico y medioambiental).'|translate}}</p>
                </div>
            </div>
            <div class="col-md-3 d-none">
            </div>
        </div>

        <div class="mt-3-rem">
            &nbsp;
        </div>
    </div>

</section>


<section class=" news-sec-desktop bg-light-theme d-md-block ">
    <div class="container-fluid  backgroud-w2b-g p-relative shadow-top ">

        <div class="row">
            <div class="col-md-12">
                <img src="assets/images/economiaCircular/2.jpg" style="z-index: 1;" />
            </div>

        </div>
        <div class="row ml-1 ">
            <div class="col-md-4" style="margin: auto;">
                <h2 class="text-custom-white ta-left" [innerHTML]="'BATERÍAS DISEÑADAS Y FABRICADAS PARA UNA SEGUNDA VIDA'|translate|nl2pbr"></h2>
                <p class="text-custom-white ta-left"> <br>
                    <br>{{'Las baterías de Nerva, diseñadas por el fabricante de vehículos BYD, forman parte de un modelo circular que busca alargar la vida útil de las mismas'|translate}} <br><br> {{'Por ello, tras 5 años de uso como batería de scooters,
                    se procede la conversión de las mismas en un sistema de almacenaje, capaz de almacenar energía en instalaciones en países en vías de desarrollo durante muchos años más.'|translate}}</p>

            </div>
            <div class="col-md-8">
                <img src="assets/images/economiaCircular/6.jpg" />
            </div>
        </div>

        <div class="row ml-1 mucho_mas">
            <div  class="col-md-4">
                <p>{{'PASO 1'|translate}}</p>
                <p> <img src="assets/images/economiaCircular/ico_1.png" /> </p>
                <p class="text_big"><span>{{'COMPRA Y VIDA ÚTIL COMO BATERÍA DEL SCOOTER'|translate}}</span></p>

                <p>{{'Al realizar la compra del scooter, se ofrecerá la opción de contratar el servicio Renting de baterías.'|translate}} <br><br> {{'Tras los 5 primeros años de vida útil, Nerva se hará cargo de sustituir las baterías. En caso de no haber
                    contratado el servicio de Renting y de estar en las condiciones requeridas, las baterías no alquiladas, podrán ser recompradas.'|translate}}</p>
                <br><br>
                <a *ngIf="mostrarPaso" class="btn-second orange-btn btb-white" data-toggle="modal" data-target="#financiacion" href="javascript:void(0);">{{'FINANCIACIÓN BATERÍAS'|translate}}  →</a>
            </div>
            <div class="col-md-4">
                <p>{{'PASO 2'|translate}}</p>
                <p> <img src="assets/images/economiaCircular/ico_2.png" /> </p>
                <p class="text_big"><span>{{'CONVERSIÓN A ALMACENADOR DE ENERGÍAR'|translate}}</span></p>

                <p>{{'Las baterías retiradas, al haber sido concebidas pensando en su segunda vida, no necesitarán ser re-trabajadas.'|translate}} <br><br> {{'Tras pasar por un proceso de categorización y calidad, se combinarán con una BMS ya diseñada para
                    este propósito, pasando convertirse en almacenadores de energía.'|translate}}</p>
                <br><br>
                <a class="btn-second orange-btn btb-white" data-toggle="modal" data-target="#conversionbateria" href="javascript:void(0);">{{'CONVERSIÓN BATERÍAS'|translate}}</a>
            </div>
            <div class="col-md-4">
                <p>{{'PASO 3'|translate}}</p>
                <p> <img src="assets/images/economiaCircular/ico_3.png" /> </p>
                <p class="text_big"><span>{{'INSTALACIÓN EN PAÍSES EN VÍAS DE DESARROLLO'|translate}}</span></p>

                <p>{{'Una vez instalado el sistema de almacenaje en países en vías de desarrollo, el usuario tendrá acceso a la información de la cantidad de energía almacenada en tiempo real, así como a la ubicación y proyecto del que forma parte.'|translate
                    }}
                </p>
                <p>{{'Nerva colabora con la fundación EKI para la llevar a cabo estos proyectos.'|translate }}</p>
                <br><br>
                <a class="btn-second orange-btn btb-white" data-toggle="modal" data-target="#localizarbateria" href="javascript:void(0);">{{'LOCALIZA TU BATERÍA'|translate}}</a>
            </div>
        </div>


    </div>

</section>
<section class=" news-sec-desktop bg-light-theme d-md-block ">
    <div class="container-fluid  backgroud-w p-relative shadow-top ">
        <div class="row ml-1">
            <div class="col-md-12 mt-10-rem">
                <p>{{'PROYECTO GLOBAL'|translate}}</p>

                <h2 class="title  text-custom-black " [innerHTML]="'ENERGÍA TRANSFRONTERIZA'|translate|nl2pbr"></h2>

            </div>
        </div>
        <div class="row ml-1 mt-3-rem">
            <div class="col-md-12">
                <p>{{'Nuestro pensamiento sistémico y búsqueda del impacto positivo, ha dado lugar a un acuerdo de colaboración con Fundación EKI, enfocados en proyectos solidarios, en los cuales se dota de energías renovables a centros educativos/ sanitarios
                    en el África Central.'|translate}}</p>
            </div>
        </div>
        <div class="row ml-1 mt-3-rem">
            <div class="col-md-12">
                <img src="assets/images/economiaCircular/4.jpg" />
            </div>
        </div>

        <div class="row ml-1 mt-3-rem ">
            <div class="col-md-4" style="margin: auto; text-align: initial;">
                <p class=" ta-left">{{'Un modelo colaborativo que contribuye al desarrollo local y a vertebrar comunidades en países en vías de desarrollo gracias a la instalación de los sistemas de almacenaje Nerva y a la gran labor social realizada por la Fundación.'|translate}}</p>
                <a class="btn black-btn mb-3" href="https://fundacioneki.org/" target="_blank">{{'DESCUBRE LA FUNDACION EKI'|translate}}</a>
            </div>
            <div class="col-md-8">
                <img src="assets/images/economiaCircular/5.jpg" />
            </div>
        </div>

        <div class="mt-3-rem">
            &nbsp;
        </div>

    </div>
</section>


<section class="popup-conversion popup" style="transition: all 0.5s ease-out;z-index: 99999; position: fixed; top:0; right:0; width:0%; height:100vh; background:black;">

    <a class="btn-second orange-btn  m-1 data-close-nav" href="javascript:void(0);" data-id="popup-conversion">X</a>
    <div class="data-popup">
        <h3>{{'RATIO DE CONVERSIÓN MOTO-ALMACENADOR DE ENERGIA'|translate}}</h3>
        <br>
        <div class="row">
            <div class="col-5">
                <p><img src="assets/images/economiaCircular/ico_4.png" width="400" height="300" /></p>
                <p>FOTO</p>
            </div>
            <div class="col-2">
                <br><br><br><br><br>
                <h1>→</h1>
            </div>
            <div class="col-5">
                <p><img src="assets/images/economiaCircular/6.jpg" width="400" height="300" /></p>
                <p>FOTO</p>
            </div>
        </div><br>
        <h6>Se necesitan 3 Scooters para ensanblar un módulo de almacenamiento de energía.</h6>
    </div>
</section>


<app-footer2></app-footer2>
<app-renting-baterias></app-renting-baterias>

<div class="modal fade" id="localizarbateria">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <div class="modal-dialog">
                    <h3>{{'LOCALIZA TU BATERÍA'|translate}}</h3>
                    <br>
                    <h6>{{'Introduce el número de serie de tu batería para buscar al que se ha destinado.'|translate}}</h6>
                    <input class="form" style="background:#050505; color: white; width: 300px;" type="search">
                    <button class=" btn " type="submit ">{{'Buscar'|translate}}</button>
                    <br><br><br>
                    <div class="row ">
                        <div class="container-modos col">
                            <div class="container "><br>
                                <p>{{'Tus baterías han sido destinadas al proyecto:'|translate}}</p>
                                <b><p>...</p></b>
                                <a class="btn-second d-none " style="color:black; background: white; display: block;" href="https://fundacioneki.org/" target="_blank">{{'DESCUBRE LA FUNDACION EKI'|translate}}</a><br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="conversionbateria">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <div class="modal-dialog">
                    <h3>{{'RATIO DE CONVERSIÓN MOTO-ALMACENADOR DE ENERGIA'|translate}}</h3>
                    <br>
                    <div class="row">
                        <div class="col-5">
                            <p><img src="assets/images/economiaCircular/ico_4.png" width="400" height="300" /></p>
                            <p style="color: gray;">{{'NERVA EXE'|translate}}</p>
                        </div>
                        <div class="col-2">
                            <br><br><br><br><br>
                            <h1>→</h1>
                        </div>
                        <div class="col-5">
                            <p><img src="assets/images/economiaCircular/6.jpg" width="400" height="300" /></p>
                            <p style="color: gray;">{{'ALMACENADOR DE ENERGIA'|translate}}</p>
                        </div>
                    </div><br>
                    <h6>{{'Se necesitan 3 Scooters para ensanblar un módulo de almacenamiento de energía.'|translate}}</h6>
                </div>
            </div>
        </div>
    </div>
</div>