import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GeoapiService } from 'src/app/services/geoapi.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-modeloExe',
  templateUrl: './modeloExe.component.html',
  styleUrls: ['./modeloExe.component.css']
})
export class modeloExeComponent implements OnInit {
  public lang: string;
  idioma: string;
  popup: boolean = false;

  language: string;
  windowWidth: number;
  italian : boolean = false
  ver : boolean = false
  




  constructor(geoApiService: GeoapiService, private route: ActivatedRoute, private translate: TranslateService, private location: Location) {
    this.windowWidth = window.innerWidth;

    this.lang = navigator.language.split("-")[0];
    console.log(this.lang)
    // geoApiService.getIPAddress().then(ipAddress => {
    //   geoApiService.getCountry(ipAddress).subscribe({
    //     next: (r: any) => {
    //       let countryIsoCode = r.message?.country?.iso_code.toLowerCase();
    

    //       if (ipAddress === '186.132.217.68' || ipAddress === '81.45.91.154') {
    //      // if (ipAddress === '81.45.91.154') {
    //         let langParts = navigator.language.split("-");
    //         countryIsoCode = langParts[0];
    //       }
    
    //       this.lang = countryIsoCode;
    //       console.log(this.lang);
    
    //       // if (this.lang === 'es') {
    //       //   this.translate.setDefaultLang('es');
    //       //   this.translate.use('es');
    //       // } else {
    //       //   this.translate.setDefaultLang('es');
    //       //   this.translate.use(countryIsoCode);
    //       // }
    
    //     },
    //     error: (err: any) => {
    //       console.error('Error fetching country information:', err);

    //     }
    //   });
    // }).catch(err => {
    //   console.error('Error fetching IP address:', err);

    // });
      this.ver = true;

  }

  ngOnInit(): void {
    
    let leng = 'en';
    this.language = leng;
    console.log(leng);
    console.log(this.language);
    let lang = sessionStorage.getItem("lang")

    
    setTimeout(() => {
      this.route.queryParams.subscribe((params)=> {
        console.log(params);
        
        if(params['lang']){
          sessionStorage.setItem("lang", params['lang']);
          this.idioma = params['lang'];

        } else if (sessionStorage.getItem("lang")) {
          let lang = sessionStorage.getItem("lang")
          let language = lang;
          this.idioma = lang;

        } else {
          let lang = 'en';
          let language = lang;
          this.idioma = lang;
          console.log(language)
        }

      });
    }, 1)



  }

  ngAfterViewInit(): void {

    this.activateLink();

  }

  activateLink() {
    const enlace = document.getElementById('elBoton');
    if (enlace) {
      enlace.click();
    }
  }

  goDown() {
    //this.scroller.scrollToAnchor("targetGreen");
    document.getElementsByClassName("news-sec-desktop")[0].scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }
  cerrarPopup() {
    this.popup = false; // Cierra el pop-up estableciendo la variable popup a false
    // Elimina el div con la clase "modal-backdrop fade show"
    const backdrop = document.querySelector('.modal-backdrop.fade.show');
    if (backdrop) {
      backdrop.remove();
    
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = event.target.innerWidth; 
  }


}
