import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { GeoapiService } from './services/geoapi.service';
declare let $: any;
declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})
export class AppComponent implements OnInit {
  location: any;
  routerSubscription: any;
  idioma: string = '';
  lang: string = '';
  isLangLoaded: boolean = false; 
  ipAddress: string = '';

  constructor(
    private router: Router,
    private translate: TranslateService,
    private geoApiService: GeoapiService,
    private route: ActivatedRoute
  ) {    

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      gtag('event', 'page_view', {
        page_path: event.urlAfterRedirects
      });
    });



    this.geoApiService.getIPAddress().then(ipAddress => {
      this.ipAddress = ipAddress; 

      this.geoApiService.getCountry(ipAddress).subscribe({
        next: (r: any) => {
          let countryIsoCode = r.message?.country?.iso_code || '';
          countryIsoCode = countryIsoCode.split('-')[0].toLowerCase();
          
          console.log(countryIsoCode);
          this.lang = countryIsoCode;
          if (this.lang === 'es') {
            sessionStorage.setItem("lang", "es");
            this.translate.setDefaultLang('en');
            this.translate.use('es');
          } 
          // else if (this.ipAddress === "186.141.138.224"){
          //   sessionStorage.setItem("lang", "it");
          //   this.translate.setDefaultLang('it');
          //   this.translate.use('it');
          // }
          else {
            this.translate.setDefaultLang('en');
            this.translate.use(countryIsoCode);
            sessionStorage.setItem("lang", countryIsoCode);
          }
          this.idioma = countryIsoCode;
          this.isLangLoaded = true; 
        },
        error: (err: any) => {
          console.error('Error fetching country information:', err);

          this.lang = 'en';
          this.translate.setDefaultLang('en');
          this.translate.use('en');
          this.isLangLoaded = true; 
        }
      });
    }).catch(err => {
      console.error('Error fetching IP address:', err);
      this.lang = 'en';
      this.translate.setDefaultLang('en');
      this.translate.use('en');
      this.isLangLoaded = true; 
    });
  }

  ngOnInit() {
    this.recallJsFuntions();
  }

  recallJsFuntions() {
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
      .subscribe(event => {
        if (this.isLangLoaded) { 
          $.getScript('assets/js/custom.js');
          window.scrollTo(0, 0);
        }
      });
  }
}
