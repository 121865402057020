<!-- Desktop Nav -->
<header>

    <div id="menu-style-3">
        <div class="container-fluid">
            <div class="dekstop-nav">
                <div class="row">
                    <div class="col-12">
                        <div class="menu-style-3">
                            <div class="side-nav">
                                <ul>
                                     <li class="button mobile-open-nav" id="mobile-open-nav">
                                        <a href="javascript:void(0)" class="text-custom-white"><i class="flaticon-menu"></i></a>
                                    </li> 
                                </ul>
                            </div>
                            <div class="logo">
                                    <a routerLink="/" style="padding-left: 70px;">
                                        <img src="assets/images/140x32logo.png" alt="Logo" >
                                    </a>
                            </div>
                            <div class="navigation">
                                <nav>
                                    <ul class="menu-item">
                                        <li class="menu-item"> <a routerLink="/modeloExe" class="text-custom-white">{{'MODELO EXE'|translate}}</a>
                                        </li>
                                        <li class="menu-item"> <a routerLink="/battery" class="text-custom-white">{{'NUESTRA BATERÍA'|translate}}</a>
                                        </li>

                                            <li *ngIf="language != 'en'">
                                                <a class="text-custom-white" routerLink="/prensa" class="text-custom-white">{{'PRENSA'|translate}}</a>
                                                <ul class="menu-item submenu">
                                                    <li > <a href="/prensa">{{'NOTAS DE PRENSA'|translate}}</a> </li>
                                                    <li > <a href="/pruebadeconduccion">{{'PRUEBAS DE CONDUCCION'|translate}}</a></li>
                                                </ul>
                                            </li>

                                        
                                        <li class="menu-item"> <a routerLink="/economiaCircular" class="text-custom-white">{{'ECONOMÍA CIRCULAR'|translate}}</a>
                                        </li>
                                        <li class="menu-item"> <a routerLink="/quiero-una-nerva" class="text-custom-white">{{'QUIERO UNA NERVA'|translate}}</a>
                                        </li>
                                        <li class="menu-item"> <a routerLink="/solicitar-prueba" class="text-custom-white">{{'CONCESIONARIOS'|translate}}</a>
                                        </li>
                                        <!-- <li class="menu-item"> 
                                            <a href="https://b2b.nerva.eco/" target="_blank" class="text-custom-white"> 
                                                {{'ACCESO B2B'|translate}}
                                            </a>
                                        </li> -->
                                        

                                    </ul>
                                </nav>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="mobile-nav">
                <div class="row">
                    <div class="col-1" style="padding-right: 2rem;">
                        <div class="mobile-nav-icons">
                            <ul>
                                <li class="button mobile-open-nav" id="mobile-open-nav">
                                    <a href="javascript:void(0)" class="text-custom-white"><i class="flaticon-menu"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="logo">
                            <a routerLink="/" style=" padding-top:0px">
                                <img class="logo-img-small" src="assets/images/logo-nerva-negro.png" alt="Logo para pantallas pequeñas">
                                <img class="logo-img-large" src="assets/images/140x32logo.png" alt="Logo para pantallas grandes">
                            </a>
                        </div>

                        </div>
                    </div>
                    
                </div>
            </div>
        </div>

    <div class="mobile-nav2">
        
        <div>
            <div id="mobile-mySidenav" class="sidenav">
                <div class="back-wrapper">
                </div>
                <img class="img-wrapper" src="assets/images/menu.jpg" alt="Logo">
                <div class="side-nav-wrapper">
                    <span class="float-right " style="right: 45%;"><img src="assets/images/140x32logo.png" alt="Logo"></span>
                    <span class="float-left">
              <a href="javascript:void(0)" class="closebtn" id="mobile-close-nav">X</a>
              </span>
                    <ul>
                        <li>
                            <a routerLink="/" class="text-custom-white">
                                <h3 class="mb-0">{{'INICIO'|translate}}</h3>
                            </a>

                        </li>

                        <li>
                            <a routerLink="/modeloExe">
                                <h3 class="mb-0">{{'MODELO EXE'|translate}}</h3>
                            </a>
                        </li>

                        <li>
                            <a routerLink="/battery">
                                <h3 class="mb-0">{{'NUESTRA BATERÍA'|translate}}</h3>
                            </a>
                        </li>
                        <li  *ngIf="language != 'en'">
                            <a routerLink="/prensa">
                                <h3 class="mb-0">{{'Notas de Prensa'|translate|uppercase}}</h3>
                            </a>
                        </li>
                        <li  *ngIf="language != 'en'">
                            <a routerLink="/pruebadeconduccion">
                                <h3 class="mb-0">{{'Pruebas de Conducción'|translate|uppercase}}</h3>
                            </a>
                        </li>
                        <li>
                            <a routerLink="/economiaCircular">
                                <h3 class="mb-0">{{'ECONOMÍA CIRCULAR'|translate}}</h3>
                            </a>
                        </li>
                        <li>
                            <a routerLink="/contacto">
                                <h3 class="mb-0">{{'CONTACTO'|translate}}</h3>
                            </a>
                        </li>
                        <li>
                            <a routerLink="/quiero-una-nerva" class="text-custom-white">
                                <h3 class="mb-0">{{'QUIERO UNA NERVA'|translate}}</h3>
                            </a>

                        </li>
                        <li>
                            <a routerLink="/solicitar-prueba" class="text-custom-white">
                                <h3 class="mb-0">{{'CONCESIONARIOS'|translate}}</h3>
                            </a>

                        </li>
                         <li>
                            <a href="https://b2b.nerva.eco/" target="_blank">
                                <h3 class="mb-0">{{'ACCESO DISTRIBUIDORES'|translate}}</h3>
                            </a>
                        </li>   
                        
                    </ul>
                    <div class="mobile-social-icon">
                        <ul>
                            <li><a href="javascript:void(0)">© 2022 NERVA</a>
                            </li>
                            <li><a href="javascript:void(0)">{{'Privacidad y condiciones legales'|translate}}</a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- Desktop Nav -->