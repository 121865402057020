import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConcesionariosService {
  headers = new HttpHeaders().set('Authorization', 'Basic MGU5YTQwMmViNDc2NGExOjU4M2VjZTRjNTMzMzI2Nw==');
  constructor(private http: HttpClient) { }

  public getConsesionarios(city: Text): Observable<any> {
    
    var filtro = ""
    if (city)
      filtro = "?city=" + city

    return this.http
      .get(`/api/method/nerva.web.getConcesionarios`+filtro,  {headers: this.headers});
  }

  public getPlace(concesionario:any): Observable<any> {
    let data ={
      "name": concesionario,
    }
    return this.http
      .post(`/api/method/atr_appointments.api.getPlace`,data , {headers: this.headers});
  }
  public getEvent(evento:any): Observable<any> {
    let data ={
      "name": evento,
    }
    return this.http
      .post(`/api/method/atr_appointments.api.getEvent`,data , {headers: this.headers});
  }

  public getAvailabeDays(concesionario: any, event:any): Observable<any> {
    let data = {
      'place': concesionario.name,
      'country': concesionario.country,
      'event': event
    }
    return this.http
      .post(`/api/method/atr_appointments.api.getAvailabeDays`,data , {headers: this.headers});
  }

  public createAppointment(solicitud): Observable<any> {
    return this.http
      .post(`/api/method/atr_appointments.api.createAppointment`,solicitud , {headers: this.headers});
  }

  public getHighlightsTestimonials(): Observable<any> {
    return this.http
      .get(`/api/method/atr_appointments.api.getHighlightsTestimonials` , {headers: this.headers});
  }

  public validarVinParaCita(dato:any): Observable<any> {
    return this.http
      .get(`/api/method/nerva.web.validarVinParaCita?matricula=`+dato , {headers: this.headers});
  }

  public getAvailableDaysBMS(datos:any): Observable<any> {
    return this.http
      .post(`/api/method/nerva.web.getAvailableDaysBMS`,datos , {headers: this.headers});
  }

  public altaCitaBMS(data:any): Observable<any> {
    return this.http
      .post(`/api/method/nerva.web.altaCitaBMS`, data , {headers: this.headers});
  }

  public getCiudadesPorPais(data:any): Observable<any> {
    return this.http
      .get(`/api/method/nerva.web.getCiudadesPorPais?pais=`+data , {headers: this.headers});
  }

  public getPlacesBMS(data:any): Observable<any> {
    return this.http
      .post(`/api/method/nerva.web.getPlacesBMS`, data, {headers: this.headers});
  }

}
