<app-header2></app-header2>
<!-- Banner sec -->


<section class="banner-slider">
    <div class="video-slider">
        <video class="video_back" preload="auto" playsinline autoplay loop [loop]="true" muted [autoplay]="true" [muted]="true" style="width: 100%;" src="/assets/videos/home/nervaBackground.mp4"></video>
    </div>
    <div class="text-left">
        <div class="banner-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div>
                            <div class="about-text-wrapper">
                                <a  id="elBoton" class="btn-second2" href="JavaScript:Void(0);" data-toggle="modal" data-target="#pruebaconduccionpopuphome"></a>
                                <h2 class="text-custom-white title">{{ 'NERVA EXE' | translate }}</h2>
                                <!-- <div *ngIf="idioma !== 'en'">
                                    <p class="text-custom-white sub-title" ng-bind-html="userComment.htmlComment">{{'UNA BATERIA ELÉCTRICA JAMAS VISTA. NERVA EXE.' | translate }} <a class="btn-second2" href="JavaScript:Void(0);" data-toggle="modal" data-target="#compra-scooter" *ngIf="lang == 'es'"> ⓘ</a><br> {{'O DESDE XXX,XX € / MES SIN ENTRADA.'| translate}}<a *ngIf="lang == 'es'" class="btn-second2" href="JavaScript:Void(0);" data-toggle="modal" data-target="#alquila-scooter">ⓘ</a></p>

                                </div> -->
                                <div class="about-text-wrapper-blocks">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-4 col-lg-4 text-custom-white block_text_slide">
                                            <span class="text_top">{{'AUTONOMÍA MÁX.'|translate}}
                                                <a class="btn-second2" href="JavaScript:Void(0);" data-toggle="modal" data-target="#modosconduccion">ⓘ</a>
                                            </span><br>
                                            <span class="text_down"><span class="text_big">150</span><sup> km</sup></span>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4 text-custom-white block_text_slide">
                                            <span class="text_top">{{'CONDUCCIÓN'|translate}}</span><br>
                                            <span class="text_down"><span class="text_big">{{'CARNET B'|translate}}</span></span>

                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4 text-custom-white block_text_slide">
                                            <span class="text_top">{{'GARANTÍA BATERÍAS'|translate}}</span><br>
                                            <span class="text_down"><span class="text_big">5 </span><sup> {{'AÑOS'|translate}}</sup></span>

                                        </div>
                                    </div>
                                </div>

                                <div class="text-custom-white pt-3">
                                    <p><br></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    <!--
 <div class="gradient">

    </div>
    -->
    <div style="width: 100%; color: white">
        <div class="row">
            <div class="col-md-2 p-0 offset-md-5 text-center">
                <a href="javascript:void(0);" onclick="Home.PlayVideo()">
                    <svg style="width: 50px; fill: white" class="hero__play-text" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M256 0a256 256 0 101 513 256 256 0 00-1-513zm0 491a235 235 0 111-470 235 235 0 01-1 470z"></path>
                        <path d="M358 247l-150-96a11 11 0 00-16 9v192a11 11 0 0016 9l150-96c3-2 5-5 5-9s-2-7-5-9zm-145 85V180l119 76-119 76z"></path>
                    </svg><br/>
                    <span class="sacar-video">{{'Ver video'|translate}}</span>
                </a>
                <a href="/solicitar-prueba"style="text-decoration: none;">
                <button id="floating-button">{{'SOLICITAR PRUEBA DE CONDUCCIÓN'|translate}}</button>
                    </a>
            </div>
        </div>
    </div>
    <video class="--is-hidden" id="home-video" controlslist="nodownload" poster="" src="/assets/videos/home/Nerva.mp4" muted="" playsinline="false" webkit-playsinline=""></video>
    <div class="d-none" style="position: absolute; bottom: 10px; width: 100%; ">
        <div class="row pl-4 pr-4" style="align-items: flex-end;">
            <div class="col-md-5 d-sm-none col-lg-5">
                <a class="btn-third orange-btn wow fadeInUp m-1" routerLink="/modeloExe">{{'DESCUBRE EXE'|translate}}</a>
                <a *ngIf="lang == 'es'" class="btn-second orange-btn wow fadeInUp m-1" href="/prueba-conduccion">{{'PRUEBA DE CONDUCCIÓN'|translate}}</a>
            </div>
            <div class="text-center   col-md-2 push-sm-2 bloque-flecha">
                <a class="btn-second flecha orange-btn wow fadeInUp m-1" (click)="goDown()" href="javascript:void(0);">↓</a>
            </div>
            <div class="col-md-5 col-lg-5 text-right">
                <a class="btn-second byd-btn wow fadeInUp m-1" style="background: #3a3a3a;" target="_blank" href="https://www.bydeurope.com/">POWERED & MANUFACTURED BY BYD <br><span style="font-weight: bold;"><u>{{'DESCUBRE BYD'|translate}}</u></span></a>
            </div>
        </div>
    </div>
</section>

<!-- News Sec Desktop -->
<section class=" news-sec-desktop bg-light-theme d-none d-md-block  ">
    <div class="container-fluid  backgroud-w p-relative shadow-top ">

        <div class="row">
            <div class="col-md-6">
                <div class="news-sec-img p-relative">
                    <a routerLink="/economiaCircular">
                        <img src="assets/images/news/news-1.jpg" class="img-fluid full-width" alt="News Image">
                    </a>
                    <div class="news-caption">
                        <div class="news-wrapper">
                            <div class="row">
                                <div class="col-md-10">
                                    <p class="text-custom-white no-margin">{{'ECONOMÍA CIRCULAR NERVA'|translate}}</p>
                                    <a routerLink="/economiaCircular">
                                        <h4 class="text-custom-white text-capitalize">{{'BATERÍAS DESTINADAS A UNA SEGUNDA VIDA'|translate|nl2pbr}}</h4>
                                    </a>
                                </div>
                                <div class="col-md-2 text-center">
                                    <div>
                                        <a class="btn-second orange-btn wow fadeInUp m-1" routerLink="/economiaCircular">→</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="news-sec-img p-relative">
                    <a routerLink="/battery">
                        <img src="assets/images/news/news-2.jpg" class="img-fluid full-width" alt="News Image">
                    </a>
                    <div class="news-caption">
                        <div class="news-wrapper">
                            <div class="row">
                                <div class="col-md-10">
                                    <p class="text-custom-white no-margin">{{'BATERIA LFP'|translate}}</p>
                                    <a routerLink="/battery">
                                        <h4 class="text-custom-white text-capitalize" >{{'BATERÍAS LFP DESARROLLADAS Y FABRICADAS POR BYD'|translate|nl2pbr}}</h4>
                                    </a>
                                </div>
                                <div class="col-md-2 text-center">
                                    <a class="btn-second orange-btn wow fadeInUp m-1" routerLink="/battery">→</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="news-sec-img p-relative">
                    <a routerLink="/prueba-conduccion">
                        <img src="assets/images/news/news-3.jpg" style="min-height: 185px;" class="img-fluid full-width" alt="News Image">
                    </a>
                    <div class="news-caption">
                        <div class="news-wrapper">
                            <div class="row">
                                <div class="col-md-10">
                                    <a routerLink="/prueba-conduccion">
                                        <h4 class="text-custom-white text-capitalize">{{'SOLICITA UNA PRUEBA DE CONDUCCIÓN SIN COMPROMISO'|translate|nl2pbr}}</h4>
                                    </a>
                                </div>
                                <div class="col-md-2 text-center">
                                    <a class="btn-second orange-btn wow fadeInUp m-1" href="/prueba-conduccion">→</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<img src="https://tracker.metricool.com/c3po.jpg?hash=9be0dc7b3b3e1749a55b8e92c7313352" />
<app-footer2></app-footer2>

<app-modos-conduccion></app-modos-conduccion>
<app-compra-scooter></app-compra-scooter>
<app-alquila-scooter></app-alquila-scooter>
<app-prueba-conduccion-popup></app-prueba-conduccion-popup>