<!-- Footer -->
<footer class="backgroud-b p-relative" style="z-index: 9001" id="footer-style-2">
    <div>

        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="text-center mt-5 mb-5 ">
                        <object data="assets/images/nerva_move_ahead.svg" class="roboto"> </object>
                    </div>
                </div>
            </div>
            <div class="row footer-border-top-down">
                <div class="col-12">
                    <div class="copyright">
                        <ul class="text-center">
                            <li><a routerLink="/" class="text-dark-white">{{'INICIO'|translate}}</a>
                            </li>
                            <li><a routerLink="/modeloExe" class="text-dark-white">{{'MODELO EXE'|translate}}</a>
                            </li>
                            <li><a routerLink="/battery" class="text-dark-white">{{'NUESTRA BATERÍA'|translate}}</a>
                            </li>
                            <li><a routerLink="/economiaCircular" class="text-dark-white">{{'ECONOMÍA CIRCULAR'|translate}}</a>
                            </li>
                            <li><a routerLink="/concesionario" class="text-dark-white">{{'CONCESIONARIOS'|translate}}</a>
                            </li>
                            <li><a routerLink="/prensa" class="text-dark-white">{{'PRENSA'|translate}}</a>
                            </li>
                            <li><a routerLink="/pruebadeconduccion" class="text-dark-white">{{'PRUEBAS DE CONDUCCION'|translate}}</a>
                            </li>
                        </ul>
                        <ul class="text-center">
                            <li style="vertical-align: middle;">
                                <a href="https://www.instagram.com/nervamobility/" target="_blank">
                                    <img class="ml-1" style="height: 2em;" src="/assets/images/icons8-instagram (1).svg" alt="">
                                  </a>
                            </li>
                            <li style="vertical-align: middle;">
                                <a href="https://www.tiktok.com/@nervamobility?is_from_webapp=1&sender_device=pc" target="_blank">
                                    <img style="height: 2.5em; padding-left:10px" src="/assets/images/icons8-tik-tok-48.png" alt="">
                                  </a>
                            </li>
                            <li style="vertical-align: middle;">
                                <a href="https://www.youtube.com/@nerva.mobility" target="_blank">
                                    <img style="height: 2.5em; padding-left:10px" src="/assets/images/icons8-youtube.svg" alt="">
                                  </a>
                            </li>
                            <li style="vertical-align: middle;">
                                <a href="https://www.facebook.com/Nervamobility" target="_blank">
                                    <img style="height: 2.5em; padding-left:10px" src="/assets/images/icons8-facebook (1).svg" alt="">
                                </a>
                            </li>
                            <li style="vertical-align: middle;">
                                <a href="https://es.linkedin.com/company/nerva.eco" target="_blank">
                                  <img style="height: 2em; padding-left:10px" src="/assets/images/linkedin-svgrepo-com.svg" alt="">
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
           
            <div class="row">
                <div class="col-12">
                    <div class="copyright">
                        <ul class="text-center">
                            <li><a href="JavaScript:Void(0);" data-toggle="modal" data-target="#privacidad-legal" class="text-daprivacidad-legalrk-white">{{'PRIVACIDAD Y LEGAL'|translate}}</a>
                            </li>
                            <li><a routerLink="/privacidad-nerva-app" class="text-daprivacidad-legalrk-white">{{'AVISO LEGAL NERVA APP'|translate}}</a>
                            </li>
                            <li><a href="javascript:void(0)" class="text-dark-white">© 2022 NERVA</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<script>
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window,document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
     fbq('init', '1095448637977559'); 
    fbq('track', 'PageView');
    </script>
    <noscript>
     <img height="1" width="1" 
    src="https://www.facebook.com/tr?id=1095448637977559&ev=PageView
    &noscript=1"/>
    </noscript>
    <!-- End Facebook Pixel Code -->
<app-privacidad-legal></app-privacidad-legal>
<!-- Footer -->