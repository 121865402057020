import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer2',
  templateUrl: './footer2.component.html',
  styleUrls: ['./footer2.component.css']
})
export class Footer2Component implements OnInit {
  public mostrarTestDrive: boolean = false;
  private storageListener: () => void;

  constructor() { }

  ngOnInit(): void {
    this.checkLanguage();


    this.storageListener = () => {
      this.checkLanguage();
    };

    window.addEventListener("storage", this.storageListener);
  }

  private checkLanguage(): void {
    const lang = sessionStorage.getItem("lang") || navigator.language.split("-")[0];
    this.mostrarTestDrive = ["es", "fr", "it"].includes(lang);
  }

  ngOnDestroy(): void {
    window.removeEventListener("storage", this.storageListener);
  }


}
