import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-economiaCircular',
  templateUrl: './economiaCircular.component.html',
  styleUrls: ['./economiaCircular.component.css']
})
export class economiaCircularComponent implements OnInit {

  constructor(private route: ActivatedRoute, private translate: TranslateService) { }
  idioma: string;
  lang: string;
  public mostrarPaso: boolean = false;


  ngOnInit(): void {
    this.checkLanguageAndInitialize();

    // setTimeout(() => {
    //   this.route.queryParams.subscribe((params)=> {
    //     console.log(params);
        
    //     if(params['lang']){
    //       sessionStorage.setItem("lang", params['lang']);
    //       this.idioma = params['lang'];
    //       this.translate.setDefaultLang('es');
    //       this.translate.use(params['lang']);
    //     } else if (sessionStorage.getItem("lang")) {
    //       let lang = sessionStorage.getItem("lang")
    //       let language = lang;
    //       this.idioma = lang;
    //       this.translate.setDefaultLang('es');
    //       this.translate.use(language);
    //     } else {
    //       let lang = navigator.language.split("-");
    //       let language = lang[0];
    //       this.idioma = lang[0];
    //       this.translate.setDefaultLang('es');
    //       this.translate.use(language);
    //       console.log(language)
    //     }

    //   });
    // }, 1)
  }
  
  goDown() {
    //this.scroller.scrollToAnchor("targetGreen");
    document.getElementsByClassName("news-sec-desktop")[0].scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }
  private checkLanguageAndInitialize(): void {
    this.route.queryParams.subscribe((params) => {
      if (params['lang']) {
        // Si hay parámetro de idioma en la URL, lo usamos
        sessionStorage.setItem("lang", params['lang']);
        this.idioma = params['lang'];
      } else if (sessionStorage.getItem("lang")) {
        // Si hay un idioma almacenado en sessionStorage, lo usamos
        this.idioma = sessionStorage.getItem("lang")!;
      } else {
        // Si no hay información previa, usamos el idioma del navegador
        this.idioma = navigator.language.split("-")[0]; // Tomamos solo el código del idioma (ej: "es")
        sessionStorage.setItem("lang", this.idioma); // Guardamos el idioma en sessionStorage
      }

      // Configurar el idioma en ngx-translate
      this.translate.setDefaultLang('es');
      this.translate.use(this.idioma);

      // Llamamos a la función para definir `mostrarPaso`
      this.initializePopup(this.idioma);
    });
  }

  private initializePopup(lang: string): void {
    this.lang = lang;
    this.mostrarPaso = ['es', 'fr', 'it'].includes(lang);
  }
  
}
