import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { GeoapiService } from './services/geoapi.service';
declare let $: any;
declare const gtag: Function; // <------------Important: the declartion for gtag is required!

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
  location: any;
  routerSubscription: any;
  idioma: string;
  lang: string;


  constructor(private router: Router, private translate: TranslateService, private geoApiService: GeoapiService, private route: ActivatedRoute) {    
     /** START : Code to Track Page View using gtag.js */
     this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
         gtag('event', 'page_view', {
            page_path: event.urlAfterRedirects
         })
        })
        /** END : Code to Track Page View  using gtag.js */

  }

  ngOnInit(){
      this.recallJsFuntions();
      setTimeout(() => {
        this.route.queryParams.subscribe((params) => {
          console.log(params);
  
            this.geoApiService.getIPAddress().then(ipAddress => {
              if (ipAddress === '190.188.177.107' || ipAddress === '81.45.91.154') {
              //if ( ipAddress === '81.45.91.154') {
                if (params['lang']) {
                  sessionStorage.setItem("lang", params['lang']);
                  this.idioma = params['lang'];
                  this.translate.setDefaultLang('en');
                  this.translate.use(params['lang']);
                  
                } else if (sessionStorage.getItem("lang")) {
                  let lang = sessionStorage.getItem("lang");
                  this.idioma = lang;
                  this.translate.setDefaultLang('en');
                  this.translate.use(lang);
                }
                else{
                  let langParts = 'es';
                  let language = langParts;
                  this.idioma = language;
                  this.translate.setDefaultLang('en');
                  this.translate.use(language);
                }
              
              } else {

                this.geoApiService.getCountry(ipAddress).subscribe({
                  next: (r: any) => {
                    let countryIsoCode = r.message?.country?.iso_code.toLowerCase();
                    this.lang = countryIsoCode;
                    if (this.lang === 'es') {
                      this.translate.setDefaultLang('en');
                      this.translate.use('es');
                    } else {
                      this.translate.setDefaultLang('en');
                      this.translate.use(countryIsoCode);
                    }
                    this.idioma = countryIsoCode;
                  },
                  error: (err: any) => {
                    console.error('Error fetching country information:', err);
                    // Manejar error aquí, posiblemente establecer un idioma por defecto
                  }
                });
              }
            }).catch(err => {
              console.error('Error fetching IP address:', err);
              // Manejar error aquí, posiblemente establecer un idioma por defecto
            });
        });
      }, 1);
      
  }

  recallJsFuntions() {
      this.router.events
      this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
      .subscribe(event => {
          $.getScript('assets/js/custom.js');
          window.scrollTo(0, 0);
      });
  }

  /*getIPAddress(): Promise<string> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', 'https://api.ipify.org?format=json', true);
      xhr.onload = () => {
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);
          resolve(response.ip);
        } else {
          reject('No se pudo obtener la dirección IP');
        }
      };
      xhr.onerror = () => {
        reject('Error de red');
      };
      xhr.send();
    });
  }*/
}


