import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ConcesionariosService } from 'src/app/services/concesionarios.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators'; // Importa el operador filter
import { Location } from '@angular/common';
import { GeoapiService } from 'src/app/services/geoapi.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-prueba-conduccion-popup',
  templateUrl: './prueba-conduccion-popup.component.html',
  styleUrls: ['./prueba-conduccion-popup.component.css']
})
export class PruebaConduccionPopupComponent implements OnInit {


  language: string;
  testimonials: any;
  url = 'https://nerva.eco/solicitar-prueba';
  testimonial: any;
  popup: boolean = false;
 popuphome: boolean = true;
  public lang: string;
  idioma: string;
  windowWidth: number;
  italian : boolean = false
  ver : boolean = false


  

  constructor(public concesionariosService: ConcesionariosService, private router: Router, private location: Location, private geoApiService: GeoapiService,
    private translate: TranslateService) {
      this.windowWidth = window.innerWidth;
      // geoApiService.getIPAddress().then(ipAddress => {
      //   geoApiService.getCountry(ipAddress).subscribe({
      //     next: (r: any) => {
      //       let countryIsoCode = r.message?.country?.iso_code.toLowerCase();
      

      //        if (ipAddress === '186.132.217.68' || ipAddress === '81.45.91.154') {
      //       //if (ipAddress === '81.45.91.154') {
      //         let langParts = navigator.language.split("-");
      //         countryIsoCode = langParts[0];
      //       }
      
      //       this.lang = countryIsoCode;
      //       console.log(this.lang);
      
      //       // if (this.lang === 'es') {
      //       //   this.translate.setDefaultLang('es');
      //       //   this.translate.use('es');
      //       // } else {
      //       //   this.translate.setDefaultLang('es');
      //       //   this.translate.use(countryIsoCode);
      //       // }
      
      //  
      //     },
      //     error: (err: any) => {
      //       console.error('Error fetching country information:', err);

      //     }
      //   });
      // }).catch(err => {
      //   console.error('Error fetching IP address:', err);

      // });
           this.ver = true;
    }

  ngOnInit(): void {
    this.popup = true;

    this.initializeLanguage();
    // Obtener el idioma del navegador
    // let lang = navigator.language.split("-");

    // this.language = lang[0];
    // console.log(lang);
    // console.log(this.language);



      if (this.location.path() === '') {
        this.popuphome = true;
        console.log(this.popuphome + 'home');
      }
      else if (this.location.path() === '?lang=fr') {
        this.popuphome = true;
        console.log(this.popuphome + 'home');
      }
      else if (this.location.path() === '?lang=it') {
        this.popuphome = true;
        console.log(this.popuphome + 'home');
      }
      else if (this.location.path() === '?lang=en') {
        this.popuphome = true;
        console.log(this.popuphome + 'home');
      }
      else if (this.location.path() === '?lang=es') {
        this.popuphome = true;
        console.log(this.popuphome + 'home');
      }
      else if (this.location.path() === '/modeloExe') {
        this.popuphome = true;
        console.log(this.popuphome + 'MODELO');
      }
    // Obtener testimonios destacados
    this.concesionariosService.getHighlightsTestimonials().subscribe({
      next: (r: any) => {
        this.testimonials = r.message;
        console.log(this.testimonials);
      },
      error: (err: any) => {
        console.error('Error al obtener testimonios:', err);
      }
    });
  }


  initializeLanguage() {
    this.lang = sessionStorage.getItem("lang");
    this.language = sessionStorage.getItem("lang");

    // if (!this.lang) {
    //   this.lang = navigator.language.split("-")[0];
    // }

    // this.translate.setDefaultLang('en');
    // this.translate.use(this.lang);
    this.idioma = this.lang;

      this.geoApiService.getIPAddress().then(ipAddress => {
        this.geoApiService.getCountry(ipAddress).subscribe({
          next: (r: any) => {
            //if (ipAddress === '190.189.226.20' || ipAddress === '81.45.91.154') {
            if ( ipAddress === '81.45.91.154') {
              if(sessionStorage.getItem("lang")){
                this.lang = sessionStorage.getItem("lang");
                this.language = sessionStorage.getItem("lang");
              }else{
                this.lang='es';
                this.language='es';
              }
            }else{
              this.lang = r.message?.country?.iso_code.toLowerCase();
              this.language = r.message?.country?.iso_code.toLowerCase();
              this.translate.use(this.lang);
              this.idioma = this.lang;
            }
            
          }
        });
      });
  }

  asignarLogo() {
    
    this.testimonial.logo = '/files/android-chrome-256x256.png';
  }
  cerrarPopup() {
    this.popuphome = false; 
    const backdrop = document.querySelector('.modal-backdrop.fade.show');
    if (backdrop) {
      backdrop.remove();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = event.target.innerWidth; 
  }
  activateLink() {
    const enlace = document.getElementById('elBoton');
    if (enlace) {
      enlace.click();
    }
  }
}
