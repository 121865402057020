<div class="modal fade" id="privacidad-legal">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <div class="modal-dialog">
                    <div class="container">
                    <textarea readonly class="textareas" name="comentarios" rows="10" cols="50">
            POLÍTICA DE PRIVACIDAD

Está en la página web nerva.eco propiedad de la empresa NERVA ECO S.L. 

Desde nuestra empresa asumimos el compromiso de garantizar la privacidad de nuestros usuarios en todo momento y de no recabar información innecesaria ya que consideramos que la confidencialidad y la seguridad son valores importantes. 

Esta que detallamos en adelante es nuestra Política de Privacidad, en ella podrá consultar como recabamos sus datos y le explicaremos las siguientes cuestiones en relación con sus datos personales: 

Quién es el responsable del tratamiento de sus datos.
Para qué finalidades recabamos los datos que le solicitamos.
Cuál es la legitimación para su tratamiento.
Durante cuánto tiempo los conservamos.
A qué destinatarios se comunican sus datos.
Cuáles son sus derechos.


¿Quién es el responsable del tratamiento de sus datos? 

NERVA ECO S.L.
B75035386
Avenida de la Libertad 7, 5A
20004 San Sebastián
ESPAÑA 


En adelante “NERVA”, “Nosotros” o el “Responsable del Tratamiento”.

Datos personales


Esta política de privacidad cubre todos los datos recopilados y utilizados por nosotros a través de la compra por parte de los clientes de productos fabricados por NERVA, así como de la descarga y uso de nuestro aplicativo (en adelante, la “Web” y “App”, indistintamente).

Por lo tanto, esta Política de Privacidad es de aplicación, a los compradores de los productos fabricados por NERVA y, por lo tanto, a los usuarios de la App y/o Web (en adelante, se denominará a todos ellos conjuntamente como, los “Usuarios”, “Usuarios app” o “Usted”).

Qué son los datos personales recopilados?

Datos Personales hace referencia a cualquier información o datos que pueda identificarlo, ya sea directa o indirectamente.

Usted debe ser mayor de 18 años o en su caso, disponer de capacidad jurídica suficiente para poder suministrar sus datos personales. 

Datos que los Usuarios nos proporcionan directamente:

A través de la compra de productos fabricados por NERVA, en concesionarios autorizados:

Datos de carácter identificativo:

Nombre y apellidos
Correo electrónico
Número de teléfono
Número de matrícula asignado al vehículo
Dirección postal
DNI


A través de la reserva/compra online de productos, en la web de NERVA:

Datos de carácter identificativo:

Nombre y apellidos
Correo electrónico
Número de teléfono
Número de matrícula asignado al vehículo.
Dirección postal
DNI
Datos de su tarjeta de crédito. A este respecto, para su seguridad, hemos confiado en el sistema de pago mediante tarjeta de crédito o débito a una pasarela de pago segura. Los datos bancarios introducidos son encriptados y transmitidos de forma segura a los servidores de la entidad bancaria y posteriormente, son verificados con el banco emisor para evitar posibles fraudes y abusos.

A través del envío de correos electrónicos:

Cualquier dato personal que nos faciliten para responder a sus consultas o dudas por correo electrónico.
A través del formulario de contacto o de prueba del vehículo de la web:

Datos de carácter identificativo:

Nombre y apellidos
Correo electrónico
DNI
NÚMERO DE TELÉFONO



A través del uso del vehículo y acceso a las funcionalidades de la App

Los titulares o propietarios de los vehículos, así como los Usuarios app, reconocen que sus datos de carácter identificativo y de geolocalización son necesarios para el disfrute del vehículo y de las funcionalidades que ofrece la App. En cualquier caso, los usuarios y sus correspondientes Usuarios app, aceptan el tratamiento de sus datos personales para los fines que se exponen en la cláusula cuarta.

A través del registro en la App como Usuario de la app: 

Datos de carácter identificativo:

Nombre y apellido
Correo electrónico
Contraseña
Datos de Geolocalización


Datos de Geolocalización:

Dirección IP

Usted garantiza que todos los datos sobre su identidad y legitimidad facilitados a NERVA a través de la compra o adquisición de productos fabricados por éste, así como los datos facilitados al utilizar la App son veraces, exactos y completos. Asimismo, se compromete a mantener actualizados sus datos. En el supuesto de que usted facilite cualquier dato falso, inexacto o incompleto o si NERVA considera que existen motivos fundados para dudar sobre la veracidad, exactitud e integridad de los mismos, NERVA podrá denegarle el acceso y uso presente o futuro de la App o de cualquiera de sus contenidos y/o servicios.

De igual forma, el Usuario debe custodiar la contraseña identificativa para hacer uso de los servicios dispuestos en la App, y se compromete a no ceder su uso a terceros.

Obligación de facilitarnos sus datos personales y consecuencias de no hacerlo.

Los datos personales solicitados son necesarios para gestionar sus solicitudes, para el uso y disfrute de los productos adquiridos y, para prestarle los servicios y funcionalidades de la App y/o Web, por lo que, si no nos los facilita, no podremos atenderle correctamente ni prestarle los servicios proporcionados a través de la App y/o Web.

En todo caso, nos reservamos el derecho de decidir sobre la incorporación o no de sus datos personales y demás información a nuestras bases de datos.

Información que usted nos proporciona indirectamente al crear una cuenta y al utilizar la App y/o Web:

Información de cómo usa nuestros servicios, las funciones que utiliza y las acciones que lleva a cabo.


Puede obtener más información al respecto, en nuestra Política de cookies 

¿Para qué utilizamos sus datos personales?

Los datos personales facilitados a través de la compra de productos de NERVA, así como de la descarga de la App y uso de la Web son necesarios para:

Facilitarle un medio para que pueda ponerse en contacto con nosotros y, poder informarle (por email) sobre nuestros productos y las opciones de compra del vehículo.
Permitir el correcto uso y disfrute de todas las funcionalidades del vehículo.
Vincular los datos personales del usuario al vehículo y la App de NERVA.
Permitir la activación y funcionamiento de la App.
Permitir la prestación por parte de NERVA de los servicios postventa o de otros servicios vinculados a los productos.
Para contactar con Usted en caso de incidencias en el proceso de producción del vehículo.
Identificarlo como usuario y poder darle acceso a las diferentes funcionalidades que están a su disposición como titular del vehículo y usuario registrado de la App.
Entregar el producto en establecimientos o tiendas de terceros (concesionarios y/o distribuidores), a solicitud del usuario.
Otras finalidades relativas al uso de la App y/o Web:

El envío de promociones y publicidad relacionada con los productos y servicios que puedan ser de su interés (envío de newsletter), previo consentimiento expreso de Usted.
Gestionar y hacer seguimiento de la relación contractual que se mantiene con los Usuarios de la App y/o Web.
Comunicarnos con usted, incluso por correo electrónico, en relación con nuevos servicios, actualizaciones o cualquier otro asunto relativo al funcionamiento de la App.
Mejorar, desarrollar y probar nuestra App.
Proteger los derechos, la propiedad y/o la seguridad de NERVA.


Por otro lado, para el correcto funcionamiento de la App y Web, utilizamos cookies técnicas y funcionales. 


Legitimación para el tratamiento de los datos personales

Permitir el correcto uso y disfrute de los productos: La base de la legitimación en este sentido, es su consentimiento expreso en el momento de la compra de los productos.

Del mismo modo, consideramos que tenemos un interés legítimo, dado que estos datos son necesarios para el correcto uso y disfrute de todas las funcionalidades de los productos, así como permitir la activación y funcionamiento de los mismos.

− Gestionar las reservas/compras de los productos ofrecidos en la web: La base legitimadora es la relación contractual y precontractual mantenida con Usted en el momento de la aceptación de los Términos y Condiciones de compra de los productos que ofrecemos.

− Gestionar el acceso como Usuario de la App: El tratamiento de sus datos es necesario para la ejecución de los términos que regulan el uso de la App. Nos está facilitando su consentimiento aceptando esta política de privacidad a través de los mecanismos de consentimiento establecidos al momento de realizar la compra.

Del mismo modo, consideramos que tenemos un interés legítimo para realizar las comprobaciones necesarias para detectar y prevenir posibles fraudes cuando accede en la App.

Gestionar su registro como Usuario de la App: El tratamiento de sus datos personales y de geolocalización son necesarios para notificar al usuario (titular del producto) sobre el uso y localización del vehículo en todo momento. La base de la legitimación en este sentido, es su consentimiento expreso en el momento del registro en la App como usuario invitado.

Compartir datos de geolocalización durante el uso del vehículo y funcionalidades de la App: La base de la legitimación en este sentido, es su consentimiento expreso y la ejecución de la relación contractual, dado que estos datos son necesarios para geolocalizar el vehículo a través de la tarjeta SIM que lleva incorporada, mostrarle su ubicación en tiempo real y registrar el histórico de rutas en la App.

Compartir datos de geolocalización durante el uso del “mapa y/o navegador” integrado en la App: La base de la legitimación en este caso, es su consentimiento expreso al gestionar y configurar los datos de ubicación en su móvil.

Envío de comunicaciones comerciales: La base legitimadora es el consentimiento expreso que nos presta (por ejemplo: cuando autoriza el envío de publicidad o de nuestra newsletter).

− Atención al usuario: Desde NERVA tenemos un interés legítimo para atender las solicitudes o consultas de los usuarios a través de los diversos medios de contacto existentes. Entendemos que el tratamiento de estos datos resulta también beneficioso para el usuario en tanto que nos permite poder atender adecuadamente y resolver las consultas planteadas.

Para la gestión de incidencias relacionadas con el uso de la App y/o Web, el tratamiento es necesario para la ejecución de la relación contractual mantenida con usted.

Cuando la consulta está relacionada con el ejercicio de los derechos sobre los que le informamos más abajo, o con reclamaciones relacionadas con nuestros servicios, lo que nos legitima para tratar los datos es el cumplimiento de obligaciones legales por nuestra parte.

Quién dispondrá de sus datos personales: 
Sus Datos Personales podrán ser comunicados a terceros, esto son:


Terceros que nos ayudan a proporcionar servicios de TI, como proveedores de la App y Web, servicios de alojamiento, mantenimiento y soporte en nuestras bases de datos, así como en nuestro software y aplicaciones que pueden contener datos sobre usted.

Terceros que nos ayudan a proporcionar servicios digitales y reseñas, CRM, análisis y motor de búsqueda.

Agencias de publicidad, marketing y medios digitales para ayudarnos a ofrecer publicidad, marketing y campañas, para analizar su efectividad y para administrar su contacto y sus preguntas.

Terceros que nos ayudan a realizar la entrega física de los productos, tales como: concesionarios y/o distribuidores.

Terceros para el cumplimiento de una normativa legal.

Los propios usuarios: estos tendrán acceso a los datos de carácter identificativo

Nos aseguraremos de que toda comunicación de su información personal que realicemos nosotros o aquellos terceros con los que compartimos su información personal, cumplan con la legislación que nos sea de aplicación.

Conservación de sus datos personales 

Conservaremos únicamente sus Datos Personales y de Geolocalización durante el periodo de tiempo que necesitemos a propósito de la finalidad para los que los tratamos, hasta que se elimine su cuenta o durante el periodo de tiempo legalmente establecido para ello.

Al eliminar su cuenta, eliminamos el contenido publicado. No obstante, conservaremos cierta información de forma anonimizada para fines estadísticos y de mejora de nuestros productos y servicios, tales como, el histórico de rutas; de manera tal que no será posible la reidentificación del usuario.

En cualquier caso, sus datos podrían conservarse por imperativo legal, por el periodo de tiempo establecido, sin embargo, tales datos quedarán bloqueados.

Los Datos Personales y de Geolocalización obtenidos a través del registro en la App, los obtenidos a través de una consulta en la Web o a través de la dirección de correo electrónico se conservarán hasta que se realice el servicio o durante el tiempo de atención para la resolución de su consulta.

Los Datos Personales obtenidos a través de su consentimiento expreso para el envío de comunicaciones de carácter comercial y/o boletines informativos, se mantendrán hasta que usted revoque el consentimiento y solicite la baja del servicio.

Es posible que retengamos algunos datos personales para cumplir con nuestras obligaciones legales o reglamentarias, así como para administrar nuestros derechos (por ejemplo, para hacer valer nuestras reclamaciones ante los Tribunales) o con fines estadísticos o históricos.

Cuando ya no necesitemos usar sus datos personales, se eliminarán de nuestros sistemas y registros o se anonimizarán para que ya no podamos identificarlos.

Qué derechos tiene usted en relación a sus datos personales:

Cualquier persona puede retirar su consentimiento en cualquier momento, cuando el mismo se haya otorgado para el tratamiento de sus datos. En ningún caso, la retirada de este consentimiento condiciona la ejecución del contrato de suscripción o las relaciones generadas con anterioridad.

Igualmente, puede ejercer los siguientes derechos:

Solicitar el acceso a sus datos personales o su rectificación cuando sean inexactos.
Solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines para los que fueron recogidos.
Solicitar la limitación de su tratamiento en determinadas circunstancias.
Solicitar la oposición al tratamiento de sus datos por motivos relacionados con su situación particular.
Solicitar la portabilidad de los datos en los casos previstos en la normativa.
Otros derechos reconocidos en las normativas aplicables.
Dónde y cómo solicitar sus Derechos: Mediante un escrito dirigido al Responsable a su dirección postal o electrónica (indicadas en el punto 1), indicando la referencia “Datos Personales”, especificando el derecho que se quiere ejercer y respecto a qué datos personales.

En caso de divergencias con la empresa en relación con el tratamiento de sus datos, puede presentar una reclamación ante la Agencia de Protección de Datos (www.agpd.es).

Seguridad de sus datos personales:

Con el objetivo de salvaguardar la seguridad de sus datos personales, le informamos que hemos adoptado todas las medidas de índole técnica y organizativa necesarias para garantizar la seguridad de los datos personales suministrados de su alteración, pérdida y tratamientos o accesos no autorizados.

Actualización de sus datos: 

Es importante que para que podamos mantener sus datos personales actualizados, nos informe siempre que haya habido alguna modificación en ellos, en caso contrario, no respondemos de la veracidad de los mismos.

No nos hacemos responsables de la política de privacidad respecto a los datos personales que pueda facilitar a terceros por medio de los enlaces disponibles en nuestra App y/o Web.

Contacto
Si tiene cualquier duda o preocupación acerca de la forma en que tratamos y usamos sus datos personales o desea ejercer cualquiera de los derechos anteriormente descritos, póngase en contacto con marketing@NERVA.eco.

            Política de cookies 
Uso de cookies
La página web nerva.eco de NERVA ECO S.L. Utiliza cookies.
Las cookies son ficheros que se descargan en su ordenador al acceder a determinadas páginas web. Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la forma en que utilice su equipo. Además, mejoran su proceso de navegación, ya que permiten que la página web ofrezca al usuario información que puede ser de su interés en función del uso que realice, del contenido de la misma.
En caso de no querer recibir cookies, por favor, configure su navegador de internet, para que las borre del disco duro de su ordenador, las bloquee o le avise en su caso de instalación de las mismas. Para continuar sin cambios en la configuración de las cookies, simplemente, continúe en la página web.
Consentimiento
Las cookies que utilizamos no almacenan dato personal alguno, ni ningún tipo de información que pueda identificarle, salvo que quieran registrarse de forma voluntaria con el fin de utilizar los servicios que ponemos a su disposición o de recibir información sobre promociones y contenidos de su interés.
Al navegar y continuar en nuestra web nos indica que está consintiendo el uso de las cookies antes enunciadas, y en las condiciones contenidas en la presente política de cookies. En caso de no estar de acuerdo envíe un correo electrónico a cookies@nerva.eco.
Cómo bloquear o eliminar cookies instaladas
Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones de su navegador. Puede encontrar información sobre cómo hacerlo, en relación con los navegadores más comunes en los links que se incluyen a continuación:
Explorer: https://support.microsoft.com/es-es/topic/c%C3%B3mo-eliminar-archivos-de-cookies-en-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc
Chrome: https://support.google.com/chrome/answer/95647?hl=es
Firefox: https://support.mozilla.org/es/kb/Borrar%20cookies
Safari: https://support.apple.com/es-es/guide/safari/sfri11471/mac
Le informamos, no obstante, de la posibilidad de que la desactivación de alguna cookie impida o dificulte la navegación o la prestación de los servicios ofrecidos en la página web.
Modificaciones
La página web nerva.eco de NERVA ECO S.L., puede modificar esta política de cookies en función de las exigencias legales. O con la finalidad de adaptar dicha política a las instrucciones dictadas por la Agencia Española de Protección de Datos.
Por esta razón, aconsejamos a los usuarios que la visiten periódicamente. Cuando se produzcan cambios significativos en esta política de cookies, se comunicarán a los usuarios, bien mediante la web o a través de correo electrónico a los usuarios registrados.
Información legal recogida en el apartado Política de Privacidad.
                    </textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>