<script type="module" src="/path/to/concesionario-prueba.js"></script>

<app-header2></app-header2>


<section id="miDiv" class="banner-slider mb-5"> 
    <div class="">
        <div class="banner-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div>
                            <div class="about-text-wrapper">
                                <h2 class="text-custom-white title">{{'Solicitar prueba de conducción'|translate|uppercase|nl2pbr}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="gradient">

    </div>
    
</section>

<section class=" news-sec-desktop bg-light-theme d-md-block mt-5" #scrollContainer>
    <div class="container-fluid  backgroud-w p-relative shadow-top" style="background: #FFF;">
        <div class="row ml-1 mb-5">
          <div class="col-md-12">  
            <div class="row">
                <div class="col">
                    <h2 class="texto-chico">{{'Seleccione punto de prueba mas cercano.'|translate}}</h2>
                    <div class="search-input">
                      
                    <p id="miDiv">{{'País' | translate }}:</p>
                    <select id="location-country">
                      <option value="0">{{'Filtrar por país' | translate }}...</option>
                      <option value="ESPAÑA">{{'España' | translate }}</option>
                      <option value="ITALIA">{{'Italia' | translate }}</option>
                      <option value="FRANCE">{{'Francia' | translate }}</option>
                    </select>
                    
                  </div>
                </div>
            </div> 
            <div id="map-container">
              <div id="locations-panel">
                <div id="miDiv">
                <div id="locations-panel-list">
                  <h1 class="search-title">
                    <img src="https://fonts.gstatic.com/s/i/googlematerialicons/place/v15/24px.svg"/>
                      {{'Encuentre su concesionario.'|translate}}
  
                  </h1>
                  <div class="search-input">
                    <input id="location-search-input" type="text" [placeholder]="'Busque su domicilio' | translate">
                    <div id="search-overlay-search" class="search-input-overlay search">
                      <button id="location-search-button">
                        <img class="icon" src="https://fonts.gstatic.com/s/i/googlematerialicons/search/v11/24px.svg" alt="Search"/>
                      </button>
                    </div>
                  </div>
                  <div class="section-name" id="location-results-section-name">
                    {{'Concesionarios.'|translate}}
                  </div>
                  <div class="results">
                    <ul id="location-results-list"></ul>
                  </div>
                </div>
                <div id="locations-panel-details">
                </div>
              </div> 
            </div>
              <div id="gmp-map">
              </div>        
            </div>
          </div>
        </div>
    </div>
  </section>
  <app-footer2></app-footer2>

