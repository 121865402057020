<app-header2></app-header2>
<!-- Banner sec -->

<section class="banner-slider">
    <div class="">
        <div class="banner-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div>
                            <div class="about-text-wrapper">
                                <h2 style="font-size: 50px;" class="text-custom-white title">{{'Una batería única'|translate|uppercase|nl2pbr}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a href="/solicitar-prueba"style="text-decoration: none;">
            <button id="floating-button">{{'SOLICITAR PRUEBA DE CONDUCCIÓN'|translate}}</button>
        </a>
    </div>
    <div class="gradient">

    </div>
    <div style="position: absolute; bottom: 10px; width: 100%; ">
        <div class="row pl-4 pr-4">
            <div class="col-md-5">
            </div>
            <div class="text-center col-md-2">
                <a class="btn-second flecha orange-btn wow fadeInUp m-1" (click)="goDown()"  href="javascript:void(0);">↓</a>
            </div>
            <div class="col-md-5 ">
                <a class="btn-second orange-btn wow fadeInUp m-1 btn-fixed-2" href="#">...</a>
            </div>
        </div>
    </div>
</section>

<section class=" news-sec-desktop bg-light-theme d-md-block ">
    <div class="container-fluid  backgroud-w p-relative shadow-top ">
        <div class="row ml-1">
            <div class="col-md-12 mt-10-rem">
                <p>{{'BATERÍAS LFP'|translate}}</p>

                <h2 class="title  text-custom-black " [innerHTML]="'Nerva EXE es el único vehículo del sector de las dos ruedas que utiliza baterías LFP fabricadas por BYD.'|translate|nl2pbr|uppercase"></h2>

            </div>
        </div>
        <div class="row ml-1 mt-3-rem">
            <div class="col-md-12">
                <!-- <p [innerHTML]="'BYD ES UNO DE LOS LÍDERES MUNDIALES DE FABRICACIÓN DE BATERÍAS LFP'|translate|nl2pbr|titlecase">.</p> -->
                <img src="assets/images/byd/BATERY BOX 2_lfp.png" />
            </div>
        </div>

        <div class="row ml-1 mt-3-rem">
            <div class="col-md-12">
                <a class="btn-second orange-btn btb-white wow fadeInUp m-1" href="/modeloExe">{{'DESCUBRE EXE'|translate}}</a>
            </div>
        </div>

        <!-- <div class="row ml-1 mt-3-rem">
            <div class="col-md-12">
                <div class="fl-bg-video" data-video-mobile="yes" data-width="1920" data-height="1080" data-mp4="/assets/videos/byd/fabricacionByd.mp4" data-mp4-type="video/mp4" data-webm-type="video/webm">
                    <video controls loop="" muted="" playsinline="" id = "videoFabricacion" style="height: 810px; max-width: 100%;">
                        <source src="/assets/videos/byd/fabricacionByd.mp4" type="video/mp4">
                    </video>
                </div>
                <p><br>{{'Vídeo de la planta de BYD del desarrollo de las baterías NERVA.'|translate}}</p>
            </div>
        </div> -->


        <div class="mt-3-rem">
            &nbsp;
        </div>
    </div>
</section>
<section class=" news-sec-desktop bg-light-theme d-md-block ">
    <div class="container-fluid  backgroud-b p-relative shadow-top ">


        <div class="row ml-1 ">
            <div class="col-md-6" style="margin: auto;">
                <h2 class="text-custom-white ta-left" [innerHTML]="'PROPIEDADES DE LAS BATERÍAS LFP FABRICADAS POR BYD'|translate|nl2pbr"></h2>
                <p class="text-custom-white ta-left"> <br>
                    <br>{{'Las baterías LFP de BYD, gracias a su estabilidad térmica se degradan más despacio, ofreciendo una mayor autonomía a las motos NERVA.'|translate}}</p>

            </div>
            <div class="col-md-6">
                <img src="assets/images/byd/09.jpg" />
            </div>
        </div>

        <div class="row ml-1 mucho_mas">
            <div class="col-md-4">
                <p>{{'MUCHO MÁS'|translate}}</p>
                <p><span class="text_big">{{'SEGURAS'|translate}}</span></p>
                <br>
                <br>
                <p>{{'Las baterías LFP no pueden arder ni explotar gracias a su estabilidad térmica. Dicha estabilidad otorga un plus de seguridad al conductor.'|translate}}</p>
                <a class="btn-second orange-btn wow fadeInUp m-1" href="JavaScript:Void(0);" data-toggle="modal" data-target="#testabuso">{{'TEST DE ABUSO'|translate}}</a>
            </div>
            <div class="col-md-4">
                <p>{{'MUCHO MÁS'|translate}}</p>
                <p><span class="text_big">{{'LONGEVAS'|translate}}</span></p>
                <br>
                <br>
                <p>{{'La larga vida útil de las baterías LFP y su elevado número de ciclos facilitan su utilización en proyectos de segunda vida. Además, su gran longevidad garantiza que puedan usarse durante muchos años en dichos proyectos.'|translate}}</p>
                <a class="btn-second orange-btn wow fadeInUp m-1" href="/economiaCircular">{{'CONOCE NUESTRA ECONOMÍA CIRCULAR'|translate}}</a>
            </div>
            <div class="col-md-4">
                <p>{{'MUCHO MÁS'|translate}}</p>
                <p><span class="text_big">{{'SOSTENIBLES'|translate}}</span></p>
                <br>
                <br>
                <p>{{'La composición de las baterías LFP no incluye ni cobalto, ni manganeso, ni nickel, excluyendo así materiales tóxicos y de explotación poco ética.'|translate}}</p>

            </div>
        </div>

        <div class="row ml-1 ">
            <div class="col-md-4">
                <h2 class="text-custom-white ta-left" [innerHTML]="'ASÍ SE COMPARAN CON LA COMPETENCIA'|translate|nl2pbr"></h2>
                <p class="text-custom-white ta-left"> <br>
                    <br> {{'NMC es la tipología de baterías más común para vehículos similares a Nerva Exe, su composición está formada por Nickel, Manganeso y Cobalto.'|translate}}<br><br> {{'Por su parte, las LFP son una nueva generación de baterías compuestas en su mayor parte por Litio y que presentan, sobre todo, una mayor longevidad.'|translate}}
                </p>

            </div>
            <div class="col-md-8 competencia mt-10-rem">
                <div class="row divisor">
                    <div class="col-md-9"> {{'AUTONOMÍA DE LAS BATERÍAS TRAS 5 AÑOS'|translate}}</div>
                </div>
                <div class="row">
                    <div class="col-md-3 text_big">{{'BATERÍA LFP'|translate}}</div>
                    <div class="col-md-9"><img src="assets/images/byd/p_1.png"></div>
                </div>
                <div class="row">
                    <div class="col-md-3 text_big">{{'BATERÍA NMC'|translate}}</div>
                    <div class="col-md-9"><img src="assets/images/byd/p_2.png"></div>
                </div>

                <div class="row divisor">
                    <div class="col-md-9"> {{'SEGURIDAD BATERÍAS'|translate}}</div>
                </div>
                <div class="row">
                    <div class="col-md-3 text_big">{{'BATERÍA LFP'|translate}}</div>
                    <div class="col-md-9"><img src="assets/images/byd/p_3.png"></div>
                </div>
                <div class="row">
                    <div class="col-md-3 text_big">{{'BATERÍA NMC'|translate}}</div>
                    <div class="col-md-9"><img src="assets/images/byd/p_4.png"></div>
                </div>

                <div class="row divisor">
                    <div class="col-md-9"> {{'VIDA ÚTIL PARA APLICACIONES DE AUTOMOCIÓN (HASTA 80% DE ESTADO DE SALUD “SOH”)'|translate}}</div>
                </div>
                <div class="row">
                    <div class="col-md-3 text_big">{{'BATERÍA LFP'|translate}}</div>
                    <div class="col-md-9 text_med">4000-6000 {{'CICLOS COMPLETOS'|translate}}</div>
                </div>
                <div class="row">
                    <div class="col-md-3 text_big">{{'BATERÍA NMC'|translate}}</div>
                    <div class="col-md-9 text_med">1000-2000 {{'CICLOS COMPLETOS'|translate}}</div>
                </div>

                <div class="row divisor">
                    <div class="col-md-9"> {{'OTRAS CARACTERÍSTICAS'|translate}}</div>
                </div>
                <div class="row">
                    <div class="col-md-3 text_big">{{'BATERÍA LFP'|translate}}</div>
                    <div class="col-md-9 text_med">{{'MATERIALES NO TÓXICOS'|translate}} <br> {{'MEJORES PRESTACIONES PARA SEGUNDA VIDA'|translate}}</div>
                </div>
                <div class="row">
                    <div class="col-md-3 text_big">{{'BATERÍA NMC'|translate}}</div>
                    <div class="col-md-9 text_med">{{'CONTIENEN COBALTO, MANGANESO Y NICKEL'|translate}}</div>
                </div>
            </div>
        </div>



    </div>

</section>
<!-- <section class=" news-sec-desktop bg-light-theme d-md-block ">
    <div class="container-fluid  backgroud-w p-relative shadow-top ">

        <div class="row ml-1">
            <div class="col-md-12 mt-10-rem">
                <p>{{'POTENCIA MUNDIAL'|translate}}</p>

                <h2 class="title  text-custom-black " [innerHTML]="'BYD ES EL GIGANTE DE LA ELECTROMOVILIDAD'|translate|nl2pbr"></h2>

            </div>
        </div>
        <div class="row ml-1 mt-3-rem">
            <div class="col-md-12">
                <p [innerHTML]="'BYD es el número 2 en el ranking mundial de fabricantes de autobuses eléctricos, con presencia en más de 200 países.'|translate|nl2pbr"></p>
            </div>
        </div>
        <div class="row ml-1 mt-3-rem">
            <div class="col-md-12">
                <img src="assets/images/byd/1.jpg" />
            </div>
        </div>
        <div class="mt-3-rem">
            &nbsp;
        </div>
    </div>

</section>


<section class=" news-sec-desktop bg-light-theme d-md-block ">
    <div class="container-fluid  backgroud-w p-relative shadow-top ">

        <div class="row ml-1">
            <div class="col-md-6">
                <img src="assets/images/byd/3.jpg" class="w-5" /></div>
            <div class="col-md-6">
                <p>{{'A día de hoy es el número 4 en el ranking mundial de fabricantes de automóviles eléctricos, habiendo sido durante 8 años el mayor fabricante de vehículos eléctricos del mundo por lo que es una de las empresas con mayor experiencia en el sector.'|translate}}</p>

            </div>
        </div>

        <div class="mt-3-rem">
            &nbsp;
        </div>
    </div>
</section>

<section class=" news-sec-desktop bg-light-theme d-md-block ">
    <div class="container-fluid  backgroud-b p-relative shadow-top ">



        <div class="row ml-1 pt-10-rem ">
            <div class="col-md-6 ">
                <p class="text-custom-white">{{'POTENCIA MULTIDISCIPLINAR'|translate}}</p>

                <h2 class="title  " [innerHTML]="'BYD ES MUCHO MÁS QUE VEHÍCULOS'|translate|nl2pbr"></h2>

            </div>
        </div>
        <div class="row ml-1 mt-3-rem">
            <div class="col-md-12">
                <p class=" text-custom-white">{{'BYD, además de ser unos de los líderes mundiales en la fabricación de vehículos eléctricos, es uno de los mayores fabricantes mundiales de baterías de litio (LFP) y de sus aplicaciones: Sistemas de almacenaje de energía, trenes, monorraíles, etc.'|translate}}
                </p>
            </div>
        </div>




        <div class="row ml-1 mt-3-rem">
            <div class="col-md-1">
            </div>
            <div class="col-md-2">
                <img src="assets/images/byd/4.jpg" />
                <p class="text-custom-white mt-4 "> {{'AUTOMOCIÓN'|translate}}</p>
            </div>
            <div class="col-md-2">
                <img src="assets/images/byd/5.jpg" />
                <p class="text-custom-white mt-4 "> {{'BATERÍAS'|translate}}</p>
            </div>
            <div class="col-md-2">
                <img src="assets/images/byd/6.jpg" />
                <p class="text-custom-white mt-4 "> {{'NUEVAS ENERGÍAS'|translate}}</p>
            </div>
            <div class="col-md-2">
                <img src="assets/images/byd/10.jpg" />
                <p class="text-custom-white mt-4 "> {{'SISTEMAS DE ALMACENAMIENTO'|translate}}</p>
            </div>
            <div class="col-md-2">
                <img src="assets/images/byd/7.jpg" />
                <p class="text-custom-white mt-4 "> {{'FERROCARRIL'|translate}}</p>
            </div>
            <div class="col-md-1">
            </div>

        </div>

        <div class="row ml-1 mt-10-rem">
            <div class="col-md-6">
                <img src="assets/images/byd/nets.png" class="w-5" /> <br><br> </div>
            <div class="col-md-6">
                <p class="text-custom-white">{{'FACTURACIÓN BYD'|translate}}</p>

                <h2 class="title  " [innerHTML]="'22,7 BILLONES DE US$ EN 2020'|translate|nl2pbr"></h2>
            </div>
        </div>



    </div>

</section> -->

<section class="popup-encargar" style="transition: all 0.5s ease-out;z-index: 99999; position: fixed; top:0; right:0; width:0%; height:100%; background:black;">
    <a id="data-close-nav" data-id="popup-encargar">Hola!</a>
</section>
<app-footer2></app-footer2>
<div class="modal fade" id="testabuso">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <div class="fl-bg-video" data-video-mobile="yes" data-width="1920" data-height="1080" data-mp4="/assets/videos/byd/test_abuso.mp4" data-mp4-type="video/mp4" data-webm-type="video/webm">
                    <video controls loop="" muted="" playsinline="" id="videoTestAbuso" style="height: 450px; max-width: 100%;">
                        <source src="/assets/videos/byd/test_abuso.mp4" type="video/mp4">
                    </video>
                </div>
            </div>
        </div>
    </div>
</div>
