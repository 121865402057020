import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContactoService {
  headers = new HttpHeaders().set('Authorization', 'Basic MGU5YTQwMmViNDc2NGExOjU4M2VjZTRjNTMzMzI2Nw==');
  constructor(private http: HttpClient) { }

  public enviarConsulta(consulta: any) {
    return this.http.post<any>(`/api/resource/Issue`,consulta, {headers: this.headers});
  }

  public crearLead(lead: any) {
    return this.http.post<any>(`/api/resource/Lead`,lead, {headers: this.headers});
  }
}
