import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Homepage2Component } from './components/pages/homepage2/homepage2.component';
import { modeloExeComponent } from './components/pages/modeloExe/modeloExe.component';
import { bydComponent } from './components/pages/byd/byd.component';
import { economiaCircularComponent } from './components/pages/economiaCircular/economiaCircular.component';
import { Error404Component } from './components/pages/error404/error404.component';
import { ContactoComponent } from './components/pages/contacto/contacto.component';
import { PruebaConduccionComponent } from './components/pages/prueba-conduccion/prueba-conduccion.component';
import { ProximamenteComponent } from './components/pages/proximamente/proximamente.component';
import { PrivacidadLegalComponent } from './components/layouts/privacidad-legal/privacidad-legal.component';
import { PrivacidadNervaAppComponent } from './components/pages/privacidad-nerva-app/privacidad-nerva-app.component';
import { ConcesionarioComponent } from './components/pages/concesionario/concesionario.component';
import { QuieroUnaNervaComponent } from './components/pages/quiero-una-nerva/quiero-una-nerva.component';
import { SolicitudPruebaComponent } from './components/pages/solicitud-prueba/solicitud-prueba.component';
import { SolicitudPrubaFormComponent } from './components/pages/solicitud-pruba-form/solicitud-pruba-form.component';
import { CuestionarioComponent } from './components/pages/cuestionario/cuestionario.component';
import { ReprograrComponent } from './components/pages/reprograr/reprograr.component';
import { ConfirmLeadComponent } from './components/pages/confirm-lead/confirm-lead.component';
import { PrensaComponent } from './components/pages/prensa/prensa.component';
import { PruebaConduccionSeccionComponent } from './components/pages/prueba-conduccion-seccion/prueba-conduccion-seccion.component';
import { CitasCambioBmsComponent } from './components/pages/citas-cambio-bms/citas-cambio-bms.component';
import { HistoricoVehiculoComponent } from './components/pages/historico-vehiculo/historico-vehiculo.component';



const routes: Routes = [


{path: '', component: Homepage2Component},
{path: 'modeloExe', component: modeloExeComponent},
{path: 'battery', component: bydComponent},
{path: 'economiaCircular', component: economiaCircularComponent},
{path: 'contacto', component: ContactoComponent},
{path: 'prueba-conduccion', component: PruebaConduccionComponent},
{path: 'proximamente', component: ProximamenteComponent},
{path: 'concesionario', component: ConcesionarioComponent},
{path: 'privacidad-nerva-app', component: PrivacidadNervaAppComponent},
{path: 'quiero-una-nerva', component: QuieroUnaNervaComponent},
{path: 'solicitar-prueba', component: SolicitudPruebaComponent},
{path: 'solicitar-prueba-form/:id', component: SolicitudPrubaFormComponent},
{path: 'cuestionario/:id/:idEn', component: CuestionarioComponent},
{path: 'reprogramar/:id/:idfecha', component: ReprograrComponent},
{path: 'confirm-lead/:email', component: ConfirmLeadComponent},
{path: 'prensa', component: PrensaComponent},
{path: 'pruebadeconduccion', component: PruebaConduccionSeccionComponent},
{path: 'cambiar-bms', component: CitasCambioBmsComponent},
{path: 'vehicle-history', component: HistoricoVehiculoComponent},
{path: '**', component: Error404Component}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
