import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-solicitud-prueba',
  templateUrl: './solicitud-prueba.component.html',
  styleUrls: ['./solicitud-prueba.component.css']
})
export class SolicitudPruebaComponent implements OnInit {

  concesionario :any;
  @ViewChild('modosconduccion') modosConduccionModal: ElementRef;


  constructor() { }

  ngOnInit(): void {
    let node = document.createElement('script');
    node.src = "/assets/js/concesionario-prueba.js";//Change to your js file
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
    
    node = document.createElement('script');
    node.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyAYK1Rx8uRxHfYrJXXNvca3XVRboVSef_4&libraries=places,geometry&solution_channel=GMP_QB_locatorplus_v4_cABCDE";//Change to your js file
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  cargarConcesionario(){
    const variableRecuperada = localStorage.getItem('concesionario');
    if (variableRecuperada !== null) {
      console.log('Valor recuperado del localStorage:', variableRecuperada);
      this.concesionario = variableRecuperada
    } else {
      console.log('La variable no existe en el localStorage.');
    }
    window.scrollTo({
      top: 700,
      left: 100,
      behavior: "smooth",
    });
    
  }

}
