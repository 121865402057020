<app-header2></app-header2>
<body>
    <div class="banner">
        <h1 style="color:white">
            {{'Notas de Prensa'| translate}}
        </h1>
        <a href="/solicitar-prueba"style="text-decoration: none;">
            <button id="floating-button">{{'SOLICITAR PRUEBA DE CONDUCCIÓN'|translate}}</button>
        </a>
    </div>
    <div *ngIf="windowWidth > 767">
        <section *ngIf="lang == 'en' || lang== 'es'">
    <div class="row">
        <div class="col-4 p-0 bordenewsrow respsize" >
            <a href="https://www.medismail.com/es/newsletters/view/MTI5Nzl8MjAyNC0wNS0wNyAxMDozMDowMHw1MjEwODU=" target="_blank">
                <img class="respimg" src="/assets/images/NdP_NERVA_BajadaPrecio_001.jpg" style="width: 100%; height: 23em;" alt="">
            </a>        
            <div>
                <a href="https://www.medismail.com/es/newsletters/view/MTI5Nzl8MjAyNC0wNS0wNyAxMDozMDowMHw1MjEwODU=" target="_blank">
                    <h4 class="textocard resptit" style="color:white"> {{'Nerva actualiza su EXE y… ¡Baja su precio!'|translate}}
                    </h4>
                    <p class="ml-5" style="color:white">2024-05-07</p>

    
                </a>
            </div>      
        </div>
        <div class="col-4 p-0 bordenewsrow" >
            <a href="/assets/pdf/NERVA-2024-01-08.pdf.crdownload.pdf" target="_blank">
                <img src="/assets/images/noticia10.webp" style="width: 100%; height: 23em;" alt="">
            </a>        
            <div>
                <a href="/assets/pdf/NERVA-2024-01-08.pdf.crdownload.pdf" target="_blank">
                    <h4 class="textocard" style="color:white">{{'Nerva EXE II, Cargo y Lift: nuevos scooters eléctricos para 2024.'| translate}}</h4>
                    <p class="ml-5" style="color:white">2024-01-08</p>

                </a>
            </div>
    
        </div>
            <div class="col-4 p-0 bordenewsrow" >
            <a href="/assets/pdf/NERVA-2023-12-12.pdf.crdownload.pdf" target="_blank">
                <img src="/assets/images/noticia11.webp" style="width: 100%; height: 23em;" alt="">
            </a>        
            <div>
                <a href="/assets/pdf/NERVA-2023-12-12.pdf.crdownload.pdf" target="_blank">
                    <h4 class="textocard" style="color:white">{{'Te descubrimos los secretos de la batería de la Nerva EXE.'| translate}}</h4>
                    <p class="ml-5" style="color:white">2023-12-12</p>

                </a>
               
            </div>      
        </div>
        <div class="col-4 p-0 bordenewsrow" >
            <a href="/assets/pdf/NERVA-2023-11-10.pdf.crdownload.pdf" target="_blank">
                <img src="/assets/images/noticia12.webp" style="width: 100%; height: 23em;" alt="">
            </a>        
            <div>
                <a href="/assets/pdf/NERVA-2023-11-10.pdf.crdownload.pdf" target="_blank">
                    <h4 class="textocard" style="color:white">{{'Burriana presenta las nuevas motos de la policía, éticas y sostenibles.'| translate}}
                    </h4>
                    <p class="ml-5" style="color:white">2023-11-10</p>

    
                </a>
            </div>      
        </div>
        <div class="col-4 p-0 bordenewsrow" >
            <a href="/assets/pdf/NERVA-2023-10-04.pdf.crdownload.pdf" target="_blank">
                <img src="/assets/images/noticia13.webp" style="width: 100%; height: 23em;" alt="">
            </a>        
            <div>
                <a href="/assets/pdf/NERVA-2023-10-04.pdf.crdownload.pdf" target="_blank">
                    <h4 class="textocard" style="color:white">{{'Guía Ecomov 2023 - Mención Nerva.'| translate}}</h4>
                    <p class="ml-5" style="color:white">2023-10-04</p>

                </a>
            </div>
    
        </div>
        <div class="col-4 p-0 bordenewsrow" >
            <a href="/assets/pdf/NERVA-2023-09-04.pdf.crdownload.pdf" target="_blank">
                <img src="/assets/images/noticia14.webp" style="width: 100%; height: 23em;" alt="">
            </a>        
            <div>
                <a href="/assets/pdf/NERVA-2023-09-04.pdf.crdownload.pdf" target="_blank">
                    <h4 class="textocard" style="color:white">{{'Declaración de la renta: cuánto me ahorro si me compro un Nerva EXE.'| translate}}
                    </h4>
                    <p class="ml-5" style="color:white">2023-09-04</p>

                </a>
            </div>      
        </div>
        <div class="col-4 p-0 bordenewsrow" >
            <a href="/assets/pdf/NERVA-2023-08-03.pdf.crdownload.pdf" target="_blank">
                <img src="/assets/images/noticia15.jpeg" style="width: 100%; height: 23em;" alt="">
            </a>        
            <div>
                <a href="/assets/pdf/NERVA-2023-08-03.pdf.crdownload.pdf" target="_blank">
                    <h4 class="textocard" style="color:white">{{'¿Cómo es vivir en el día a día con una moto eléctrica?'| translate}}</h4>
                        <p class="ml-5" style="color:white">2023-08-03</p>

    
                </a>
               
            </div>      
        </div>
        <div class="col-4 p-0 bordenewsrow" >
            <a href="/assets/pdf/NERVA-2023-07-07.pdf" target="_blank">
                <img src="/assets/images/noticia16.webp" style="width: 100%; height: 23em;" alt="">
            </a>        
            <div>
                <a href="/assets/pdf/NERVA-2023-07-07.pdf" target="_blank">
                    <h4 class="textocard" style="color:white"> {{'Nerva EXE, el scooter eléctrico para los amantes de las motos.'| translate}}</h4>
                        <p class="ml-5" style="color:white">2023-07-07</p>

    
                </a>
            </div>
    
        </div>
        <div class="col-4 p-0 bordenewsrow respsize" >
            <a href="/assets/pdf/NERVA-2023-06-05.pdf" target="_blank">
                <img src="/assets/images/25 PL.jpg" style="width: 100%; height: 23em;" alt="">
            </a>        
            <div>
                <a href="/assets/pdf/NERVA-2023-06-05.pdf" target="_blank">
                    <h4 class="textocard" style="color:white">{{'Nerva EXE - Prueba en Madrid por vías rápidas.'| translate}}</h4>
                    <p class="ml-5" style="color:white">2023-06-05</p>

                </a>
               
            </div>      
        </div>
        <div class="col-4 p-0 bordenewsrow respsize" >
            <a href="/assets/pdf/NERVA-2023-05-04.pdf" target="_blank">
                <img class="respimg" src="/assets/images/noticia18.webp" style="width: 100%; height: 23em;" alt="">
            </a>        
            <div>
                <a href="/assets/pdf/NERVA-2023-05-04.pdf" target="_blank">
                    <h4 class="textocard resptit" style="color:white"> Descubre en Danara Motor el maxi scooter eléctrico NERVA EXE.
                    </h4>
                    <p class="ml-5" style="color:white">2023-05-04</p>

    
                </a>
            </div>      
        </div>
        <div class="col-4 p-0 bordenewsrow" >
            <a href="/assets/pdf/NERVA-2023-02-01.pdf" target="_blank">
                <img src="/assets/images/noticia9.webp" style="width: 100%; height: 23em;" alt="">
            </a>        
            <div>
                <a href="/assets/pdf/NERVA-2023-02-01.pdf" target="_blank">
                    <h4 class="textocard" style="color:white">{{'Tres formas de ahorrar con un scooter eléctrico.'| translate}}
                    </h4>
                    <p class="ml-5" style="color:white">2023-02-01</p>

                </a>
            </div>      
        </div>
        <div class="col-4 p-0 bordenewsrow" >
            <a href="/assets/pdf/NERVA-2023-01-04 (1).pdf" target="_blank">
                <img src="/assets/images/noticia8.webp" style="width: 100%; height: 23em;" alt="">
            </a>        
            <div>
                <a href="/assets/pdf/NERVA-2022-12-02.pdf" target="_blank">
                    <h4 class="textocard" style="color:white">{{'Nerva presentó dos avanzados prototipos de sus nuevos modelos.'| translate}}</h4>
                        <p class="ml-5" style="color:white">2022-12-02</p>

                </a>
               
            </div>      
        </div>
        <div class="col-4 p-0 bordenewsrow" >
            <a href="/assets/pdf/NERVA-2022-11-03 (1).pdf" target="_blank">
                <img src="/assets/images/noticia7.webp" style="width: 100%; height: 23em;" alt="">
            </a>        
            <div>
                <a href="/assets/pdf/NERVA-2022-11-03 (1).pdf" target="_blank">
                    <h4 class="textocard" style="color:white"> {{'Nerva Exe | Motorama Madrid confirma el impulso del mundo de la moto.'| translate}}</h4>
                        <p class="ml-5" style="color:white">2022-11-03</p>

    
                </a>
            </div>
    
        </div>
        <div class="col-4 p-0 bordenewsrow" >
            <a href="/assets/pdf/NERVA-2022-10-04 (1)" target="_blank">
                <img src="/assets/images/20.webp" style="width: 100%; height: 23em;" alt="">
            </a>        
            <div>
                <a href="/assets/pdf/NERVA-2022-10-04 (1).pdf" target="_blank">
                    <h4 class="textocard" style="color:white">{{'El maxi scooter eléctrico NERVA EXE aumenta su capacidad de carga.'| translate}}
                    </h4>
                    <p class="ml-5" style="color:white">2022-10-04</p>

    
                </a>
               
            </div>      
        </div>
        <div class="col-4 p-0 bordenewsrow" >
            <a href="/assets/pdf/NERVA-2022-09-02.pdf" target="_blank">
                <img src="/assets/images/noticia5.webp" style="width: 100%; height: 23em;" alt="">
            </a>        
            <div>
                <a href="/assets/pdf/NERVA-2022-09-02.pdf" target="_blank">
                    <h4 class="textocard" style="color:white">{{'Prueba scooter eléctrico Nerva EXE.'| translate}}</h4>
                    <p class="ml-5" style="color:white">2022-09-02</p>

                </a>
               
            </div>      
        </div>

        <div class="col-4 p-0 bordenewsrow" >
            <a href="/assets/pdf/NERVA-2022-06-01" target="_blank">
                <img src="/assets/images/noticia3.webp" style="width: 100%; height: 23em;" alt="">
            </a>        
            <div>
                <a href="/assets/pdf/NERVA-2022-06-01.pdf" target="_blank">
                    <h4 class="textocard" style="color:white">{{'El secreto está en sus baterías.'| translate}}
                    </h4>
                    <p class="ml-5" style="color:white">2022-06-01</p>
    
                </a>
               
            </div>      
        </div>
        <div class="col-4 p-0 bordenewsrow" >
            <a href="/assets/pdf/NERVA-2022-05-04.pdf" target="_blank">
                <img src="/assets/images/Nerva_press-59.webp" style="width: 100%; height: 23em;" alt="">
            </a>        
            <div>
                <a href="/assets/pdf/NERVA-2022-05-04.pdf" target="_blank">
                    <h4 class="textocard" style="color:white">{{'NERVA: escúter urbano EXE.'| translate}}</h4>
                    <p class="ml-5" style="color:white">2022-05-04</p>

                </a>
            </div>
    
        </div>
        <div class="col-4 p-0 bordenewsrow" >
            <a href="/assets/pdf/NERVA-2022-04-07.pdf" target="_blank">
                <img src="/assets/images/Nerva_press-1.webp" style="width: 100%; height: 23em;" alt="">
            </a>        
            <div>
                <a href="/assets/pdf/NERVA-2022-04-07.pdf" target="_blank">
                    <h4 class="textocard" style="color:white">{{'NERVA desvela los detalles de su nuevo e-scooter GT para uso urbano: EXE.'| translate}}</h4>
                    <p class="ml-5" style="color:white">2022-04-07</p>
    
                </a>
               
            </div>      
        </div>
        <div class="col-4 p-0 bordenewsrow" >
            <a href="/assets/pdf/NERVA-2022-02-28.pdf" target="_blank">
                <img src="/assets/images/noticia1.jpg" style="width: 100%; height: 23em;" alt="">
            </a>        
            <div>
                <a href="/assets/pdf/NERVA-2022-02-28.pdf" target="_blank">
                    <h4 class="textocard" style="color:white">{{'Nace NERVA Mobility, la nueva marca española de movilidad eléctrica.'| translate}}</h4>
                    <p class="ml-5" style="color:white">2022-02-28</p>
    
                </a>
            </div>
    
        </div>
    </div>
    <div class="row">

        
    </div>
    <div class="row">
      
    </div>
    <div class="row">
       
    </div>
    <div class="row">
       
    </div>



    
<div class="row">
    
</div>
</section>

<section *ngIf="lang == 'fr'">
    <div class="row">
        <div class="col-4 p-0 bordenewsrow" >
            <a href="/assets/pdf/FRANCE _ NERVA EXE PRESS CLIPPING .pdf" target="_blank">
                <img src="/assets/images/noticia3.webp" style="width: 100%; height: 23em;" alt="">
            </a>        
            <div>
                <a href="/assets/pdf/FRANCE _ NERVA EXE PRESS CLIPPING .pdf" target="_blank">
                    <h4 class="textocard" style="color:white">{{'Nerva EXE.'| translate}}
                    </h4>
                    <p class="ml-5" style="color:white">2022-06-01</p>
    
                </a>
               
            </div>      
        </div>
        <div class="col-4 p-0 bordenewsrow" style="display: none;">
            <a href="/assets/pdf/NERVA-2022-04-07.pdf" target="_blank">
                <img src="/assets/images/Nerva_press-1.webp" style="width: 100%; height: 23em;" alt="">
            </a>        
            <div>
                <a href="/assets/pdf/NERVA-2022-04-07.pdf" target="_blank">
                    <h4 class="textocard" style="color:white">{{'NERVA desvela los detalles de su nuevo e-scooter GT para uso urbano: EXE.'| translate}}</h4>
                    <p class="ml-5" style="color:white">2022-04-07</p>
    
                </a>
               
            </div>      
        </div>
        <div class="col-4 p-0 bordenewsrow" style="display: none;" >
            <a href="/assets/pdf/NERVA-2022-02-28.pdf" target="_blank">
                <img src="/assets/images/noticia1.webp" style="width: 100%; height: 23em;" alt="">
            </a>        
            <div>
                <a href="/assets/pdf/NERVA-2022-02-28.pdf" target="_blank">
                    <h4 class="textocard" style="color:white">{{'Nace NERVA Mobility, la nueva marca española de movilidad eléctrica.'| translate}}

                    </h4>
                    <p class="ml-5" style="color:white">2022-02-28</p>
    
                </a>
            </div>
    
        </div>
    </div>    
</section>

<section *ngIf="lang == 'it'">
    <div class="row">
        <div class="col-4 p-0 bordenewsrow" >
            <a href="https://emovingmag.it/2023/06/29/exe-il-maxi-scooter-del-brand-spagnolo-nerva-con-batterie-lfp-di-byd/" target="_blank">
                <img src="/assets/images/noticiait3.webp" style="width: 100%; height: 23em;" alt="">
            </a>        
            <div>
                <a href="https://emovingmag.it/2023/06/29/exe-il-maxi-scooter-del-brand-spagnolo-nerva-con-batterie-lfp-di-byd/" target="_blank">
                    <h4 class="textocard" style="color:white">{{'EXE: IL MAXI SCOOTER DEL BRAND SPAGNOLO NERVA CON BATTERIE LFP DI BYD.'| translate}}</h4>
                    <p class="ml-5" style="color:white">02-7-2023</p>
    
                </a>
            </div>
    
        </div>
        <div class="col-4 p-0 bordenewsrow" >
            <a href="https://www.gazzetta.it/motori/la-mia-moto/prove-scooter/30-06-2023/nerva-exe-caratteristiche-prezzo-e-prova-dello-scooter-elettrico-spagnolo.shtml" target="_blank">
                <img src="/assets/images/noticiait1.webp" style="width: 100%; height: 23em;" alt="">
            </a>        
            <div>
                <a href="https://www.gazzetta.it/motori/la-mia-moto/prove-scooter/30-06-2023/nerva-exe-caratteristiche-prezzo-e-prova-dello-scooter-elettrico-spagnolo.shtml" target="_blank">
                    <h4 class="textocard" style="color:white">{{'Nerva debutta in Italia con lo scooter elettrico GT Exe.'| translate}}
                    </h4>
                    <p class="ml-5" style="color:white">30-6-2023</p>
    
                </a>
               
            </div>      
        </div>
        <div class="col-4 p-0 bordenewsrow" >
            <a href="https://www.moto.it/prove/nerva-exe-abbiamo-provato-lo-scooter-elettrico-gt-con-batterie-lfp.html" target="_blank">
                <img src="/assets/images/Nerva_press-1.webp" style="width: 100%; height: 23em;" alt="">
            </a>        
            <div>
                <a href="https://www.moto.it/prove/nerva-exe-abbiamo-provato-lo-scooter-elettrico-gt-con-batterie-lfp.html" target="_blank">
                    <h4 class="textocard" style="color:white">{{'Nerva Exe: abbiamo provato lo scooter elettrico GT con batterie LFP.'| translate}}</h4>
                    <p class="ml-5" style="color:white">30-6-2023</p>
    
                </a>
               
            </div>      
        </div>
        <div class="col-4 p-0 bordenewsrow" >
            <a href="https://www.motociclismo.it/prova-scooter-elettrico-nerva-exe-2023-83048" target="_blank">
                <img src="/assets/images/noticiait2.webp" style="width: 100%; height: 23em;" alt="">
            </a>        
            <div>
                <a href="https://www.motociclismo.it/prova-scooter-elettrico-nerva-exe-2023-83048" target="_blank">
                    <h4 class="textocard" style="color:white">{{'TEST - Nerva EXE: lo scooterone elettrico da città che aiuta l\'Africa.'| translate}}</h4>
                    <p class="ml-5" style="color:white">30-6-2023</p>
    
                </a>
            </div>
    
        </div>
        <div class="col-4 p-0 bordenewsrow" >
            <a href="https://www.ansa.it/canale_motori/notizie/due_ruote/2023/06/28/nerva-sbarca-in-italia-con-lo-scooter-elettrico-exe_2270ba49-b637-4acc-a043-7338af4f0bbf.html#:~:text=La%20batteria%20riutilizzata%20in%20un%20programma%20umanitario%20in%20Africa&text=Arriva%20in%20Italia%20Exe%2C%20lo,durata%20e%20alta%20capacit%C3%A0%20specifica" target="_blank">
                <img src="/assets/images/noticiait4.webp" style="width: 100%; height: 23em;" alt="">
            </a>        
            <div>
                <a href="https://www.ansa.it/canale_motori/notizie/due_ruote/2023/06/28/nerva-sbarca-in-italia-con-lo-scooter-elettrico-exe_2270ba49-b637-4acc-a043-7338af4f0bbf.html#:~:text=La%20batteria%20riutilizzata%20in%20un%20programma%20umanitario%20in%20Africa&text=Arriva%20in%20Italia%20Exe%2C%20lo,durata%20e%20alta%20capacit%C3%A0%20specifica" target="_blank">
                    <h4 class="textocard" style="color:white">{{'Nerva sbarca in Italia con lo scooter elettrico Exe.'| translate}}</h4>
                    <p class="ml-5" style="color:white">28-6-2023</p>
    
                </a>
            </div>
    
        </div>
        <div class="col-4 p-0 bordenewsrow" >
            <a href="https://www.motociclismo.it/prova-scooter-elettrico-nerva-exe-2023-83048" target="_blank">
                <img src="/assets/images/noticia1.jpg" style="width: 100%; height: 23em;" alt="">
            </a>        
            <div>
                <a href="https://www.motociclismo.it/prova-scooter-elettrico-nerva-exe-2023-83048" target="_blank">
                    <h4 class="textocard" style="color:white">{{'Prova Nerva Exe, l´elettrico solidale.'| translate}}</h4>
                    <p class="ml-5" style="color:white">02-6-2023</p>
    
                </a>
            </div>
    
        </div>



    </div>    
</section>
</div>


<!-- RESPONSIVE -->
<div *ngIf="windowWidth < 767">

    <section *ngIf="lang == 'en' || lang== 'es'">
        <div class="row">
            <div class="col-6 p-0 bordenewsrow" >
                <a href="/assets/pdf/NERVA-2023-12-12.pdf.crdownload.pdf" target="_blank">
                    <img src="/assets/images/noticia11.webp" style="width: 100%; height: 10em;" alt="">
                </a>        
                <div>
                    <a href="/assets/pdf/NERVA-2023-12-12.pdf.crdownload.pdf" target="_blank">
                        <h4 class="textocard " style="color:white; font-size:18px">{{'Te descubrimos los secretos de la batería de la Nerva EXE.'| translate}}</h4>
                        <p class="ml-5 mt-5" style="color:white">2023-12-12</p>
    
                    </a>
                   
                </div>      
            </div>
            <div class="col-6 p-0 bordenewsrow" >
                <a href="/assets/pdf/NERVA-2023-11-10.pdf.crdownload.pdf" target="_blank">
                    <img src="/assets/images/noticia12.webp" style="width: 100%; height: 10em;" alt="">
                </a>        
                <div>
                    <a href="/assets/pdf/NERVA-2023-11-10.pdf.crdownload.pdf" target="_blank">
                        <h4 class="textocard" style="color:white; font-size:18px">{{'Burriana presenta las nuevas motos de la policía, éticas y sostenibles.'| translate}}
                        </h4>
                        <p class="ml-5" style="color:white">2023-11-10</p>
    
        
                    </a>
                </div>      
            </div>
        </div>
        <div class="row">
            
            <div class="col-6 p-0 bordenewsrow" >
                <a href="/assets/pdf/NERVA-2023-10-04.pdf.crdownload.pdf" target="_blank">
                    <img src="/assets/images/noticia13.webp" style="width: 100%; height: 10em;" alt="">
                </a>        
                <div>
                    <a href="/assets/pdf/NERVA-2023-10-04.pdf.crdownload.pdf" target="_blank">
                        <h4 class="textocard" style="color:white; font-size:18px">{{'Guía Ecomov 2023 - Mención Nerva.'| translate}}</h4>
                        <p class="ml-5" style="color:white">2023-10-04</p>
    
                    </a>
                </div>
        
            </div>
            <div class="col-6 p-0 bordenewsrow" >
                <a href="/assets/pdf/NERVA-2023-09-04.pdf.crdownload.pdf" target="_blank">
                    <img src="/assets/images/noticia14.webp" style="width: 100%; height: 10em;" alt="">
                </a>        
                <div>
                    <a href="/assets/pdf/NERVA-2023-09-04.pdf.crdownload.pdf" target="_blank">
                        <h4 class="textocard " style="color:white; font-size:18px" >{{'Declaración de la renta: cuánto me ahorro si me compro un Nerva EXE.'| translate}}
                        </h4>
                        <p class="ml-5" style="color:white">2023-09-04</p>
    
                    </a>
                </div>      
            </div>
            <div class="col-6 p-0 bordenewsrow" >
                <a href="/assets/pdf/NERVA-2023-08-03.pdf.crdownload.pdf" target="_blank">
                    <img src="/assets/images/noticia15.jpeg" style="width: 100%; height: 10em;" alt="">
                </a>        
                <div>
                    <a href="/assets/pdf/NERVA-2023-08-03.pdf.crdownload.pdf" target="_blank">
                        <h4 class="textocard " style="color:white; font-size:18px">{{'¿Cómo es vivir en el día a día con una moto eléctrica?'| translate}}</h4>

                            <p class="ml-5" style="color:white">2023-08-03</p>
    
        
                    </a>
                   
                </div>      
            </div>
            <div class="col-6 p-0 bordenewsrow" >
                <a href="/assets/pdf/NERVA-2023-07-07.pdf" target="_blank">
                    <img src="/assets/images/noticia16.webp" style="width: 100%; height: 10em;" alt="">
                </a>        
                <div>
                    <a href="/assets/pdf/NERVA-2023-07-07.pdf" target="_blank">
                        <h4 class="textocard " style="color:white; font-size:18px"> {{'Nerva EXE, el scooter eléctrico para los amantes de las motos.'| translate}}</h4>
                            <p class="ml-5" style="color:white">2023-07-07</p>
    
        
                    </a>
                </div>
        
            </div>
    



        </div>
        <div class="row">
            <div class="col-6 p-0 bordenewsrow respsize" >
                <a href="/assets/pdf/NERVA-2023-06-05.pdf" target="_blank">
                    <img src="/assets/images/niticia17.webp" style="width: 100%; height: 10em;" alt="">
                </a>        
                <div>
                    <a href="/assets/pdf/NERVA-2023-06-05.pdf" target="_blank">
                        <h4 class="textocard " style="color:white; font-size:18px">{{'Nerva EXE - Prueba en Madrid por vías rápidas.'| translate}}</h4>
                        <p class="ml-5" style="color:white">2023-06-05</p>
    
                    </a>
                   
                </div>      
            </div>
            <div class="col-6 p-0 bordenewsrow respsize" >
                <a href="https://www.medismail.com/es/newsletters/view/MTI5Nzl8MjAyNC0wNS0wNyAxMDozMDowMHw1MjEwODU=" target="_blank">
                    <img class="respimg" src="/assets/images/NdP_NERVA_BajadaPrecio_001.jpg" style="width: 100%; height: 10em;" alt="">
                </a>        
                <div>
                    <a href="https://www.medismail.com/es/newsletters/view/MTI5Nzl8MjAyNC0wNS0wNyAxMDozMDowMHw1MjEwODU=" target="_blank">
                        <h4 class="textocard " style="color:white; font-size:18px"> Nerva actualiza su EXE y… ¡Baja su precio!
                        </h4>
                        <p class="ml-5" style="color:white">2023-05-04</p>
    
        
                    </a>
                </div>      
            </div>
            <div class="col-6 p-0 bordenewsrow respsize" >
                <a href="/assets/pdf/NERVA-2023-05-04.pdf" target="_blank">
                    <img class="respimg" src="/assets/images/noticia18.webp" style="width: 100%; height: 10em;" alt="">
                </a>        
                <div>
                    <a href="/assets/pdf/NERVA-2023-05-04.pdf" target="_blank">
                        <h4 class="textocard " style="color:white; font-size:18px"> Descubre en Danara Motor el maxi scooter eléctrico NERVA EXE.
                        </h4>
                        <p class="ml-5" style="color:white">2023-04-23</p>
    
        
                    </a>
                </div>      
            </div>

        </div>
       
        <div class="row">
            <div class="col-6 p-0 bordenewsrow" >
                <a href="/assets/pdf/NERVA-2024-01-08.pdf.crdownload.pdf" target="_blank">
                    <img src="/assets/images/noticia10.webp" style="width: 100%; height: 10em;" alt="">
                </a>        
                <div>
                    <a href="/assets/pdf/NERVA-2024-01-08.pdf.crdownload.pdf" target="_blank">
                        <h4 class="textocard" style="color:white; font-size:18px">{{'Nerva EXE II, Cargo y Lift: nuevos scooters eléctricos para 2024.'| translate}}</h4>
                        <p class="ml-5" style="color:white">2024-01-08</p>
    
                    </a>
                </div>
        
            </div>
            <div class="col-6 p-0 bordenewsrow" >
                <a href="/assets/pdf/NERVA-2023-02-01.pdf" target="_blank">
                    <img src="/assets/images/noticia9.webp" style="width: 100%; height: 10em;" alt="">
                </a>        
                <div>
                    <a href="/assets/pdf/NERVA-2023-02-01.pdf" target="_blank">
                        <h4 class="textocard" style="color:white; font-size:18px">{{'Tres formas de ahorrar con un scooter eléctrico.'| translate}}
                        </h4>
                        <p class="ml-5" style="color:white">2023-02-01</p>
    
                    </a>
                </div>      
            </div>
            <div class="col-6 p-0 bordenewsrow" >
                <a href="/assets/pdf/NERVA-2023-01-04 (1).pdf" target="_blank">
                    <img src="/assets/images/noticia8.webp" style="width: 100%; height: 10em;" alt="">
                </a>        
                <div>
                    <a href="/assets/pdf/NERVA-2022-12-02.pdf" target="_blank">
                        <h4 class="textocard" style="color:white; font-size:18px">{{'Nerva presentó dos avanzados prototipos de sus nuevos modelos.'| translate}}</h4>
                            <p class="ml-5" style="color:white">2022-12-02</p>
    
                    </a>
                   
                </div>      
            </div>
            <div class="col-6 p-0 bordenewsrow" >
                <a href="/assets/pdf/NERVA-2022-11-03 (1).pdf" target="_blank">
                    <img src="/assets/images/noticia7.webp" style="width: 100%; height: 10em;" alt="">
                </a>        
                <div>
                    <a href="/assets/pdf/NERVA-2022-11-03 (1).pdf" target="_blank">
                        <h4 class="textocard" style="color:white; font-size:18px"> {{'Nerva Exe | Motorama Madrid confirma el impulso del mundo de la moto.'| translate}}</h4>
                            <p class="ml-5 mt-5" style="color:white">2022-11-03</p>
    
        
                    </a>
                </div>
        
            </div>
            <div class="col-6 p-0 bordenewsrow" >
                <a href="/assets/pdf/NERVA-2022-10-04 (1)" target="_blank">
                    <img src="/assets/images/20.webp" style="width: 100%; height: 10em;" alt="">
                </a>        
                <div>
                    <a href="/assets/pdf/NERVA-2022-10-04 (1).pdf" target="_blank">
                        <h4 class="textocard" style="color:white; font-size:18px">{{'El maxi scooter eléctrico NERVA EXE aumenta su capacidad de carga.'| translate}}
                        </h4>
                        <p class="ml-5" style="color:white">2022-10-04</p>
    
        
                    </a>
                   
                </div>      
            </div>
            <div class="col-6 p-0 bordenewsrow" >
                <a href="/assets/pdf/NERVA-2022-09-02.pdf" target="_blank">
                    <img src="/assets/images/noticia5.webp" style="width: 100%; height: 10em;" alt="">
                </a>        
                <div>
                    <a href="/assets/pdf/NERVA-2022-09-02.pdf" target="_blank">
                        <h4 class="textocard" style="color:white; font-size:18px">{{'Prueba scooter eléctrico Nerva EXE.'| translate}}</h4>
                        <p class="ml-5" style="color:white">2022-09-02</p>
    
                    </a>
                   
                </div>      
            </div>
            <div class="col-6 p-0 bordenewsrow" >
                <a href="/assets/pdf/NERVA-2022-06-01" target="_blank">
                    <img src="/assets/images/noticia3.webp" style="width: 100%; height: 10em;" alt="">
                </a>        
                <div>
                    <a href="/assets/pdf/NERVA-2022-06-01.pdf" target="_blank">
                        <h4 class="textocard" style="color:white; font-size:18px">{{'El secreto está en sus baterías.'| translate}}
                        </h4>
                        <p class="ml-5" style="color:white">2022-06-01</p>
        
                    </a>
                   
                </div>      
            </div>
            <div class="col-6 p-0 bordenewsrow" >
                <a href="/assets/pdf/NERVA-2022-05-04.pdf" target="_blank">
                    <img src="/assets/images/Nerva_press-59.webp" style="width: 100%; height: 10em;" alt="">
                </a>        
                <div>
                    <a href="/assets/pdf/NERVA-2022-05-04.pdf" target="_blank">
                        <h4 class="textocard" style="color:white; font-size:18px">{{'NERVA: escúter urbano EXE.'| translate}}</h4>
                        <p class="ml-5" style="color:white">2022-05-04</p>
    
                    </a>
                </div>
        
            </div>
  
            <div class="col-6 p-0 bordenewsrow" >
                <a href="/assets/pdf/NERVA-2022-04-07.pdf" target="_blank">
                    <img src="/assets/images/Nerva_press-1.webp" style="width: 100%; height: 10em;" alt="">
                </a>        
                <div>
                    <a href="/assets/pdf/NERVA-2022-04-07.pdf" target="_blank">
                        <h4 class="textocard" style="color:white; font-size:18px">{{'NERVA desvela los detalles de su nuevo e-scooter GT para uso urbano: EXE.'| translate}}</h4>
                        <p class="ml-5 mt-5" style="color:white">2022-04-07</p>
        
                    </a>
                   
                </div>      
            </div>
            <div class="col-6 p-0 bordenewsrow" >
                <a href="/assets/pdf/NERVA-2022-02-28.pdf" target="_blank">
                    <img src="/assets/images/noticia1.jpg" style="width: 100%; height: 10em;" alt="">
                </a>        
                <div>
                    <a href="/assets/pdf/NERVA-2022-02-28.pdf" target="_blank">
                        <h4 class="textocard" style="color:white; font-size:18px">{{'Nace NERVA Mobility, la nueva marca española de movilidad eléctrica.'| translate}}</h4>
                        <p class="ml-5 mt-5" style="color:white">2022-02-28</p>
        
                    </a>
                </div>
        
            </div>
        </div>

    </section>
    
    <section *ngIf="lang == 'fr'">
        <div class="row">
            <div class="col-6 p-0 bordenewsrow" >
                <a href="/assets/pdf/FRANCE _ NERVA EXE PRESS CLIPPING .pdf" target="_blank">
                    <img src="/assets/images/noticia3.webp" style="width: 100%; height: 10em;" alt="">
                </a>        
                <div>
                    <a href="/assets/pdf/FRANCE _ NERVA EXE PRESS CLIPPING .pdf" target="_blank">
                        <h4 class="textocard" style="color:white; font-size:18px">{{'Nerva EXE.'| translate}}
                        </h4>
                        <p class="ml-5" style="color:white">2022-06-01</p>
        
                    </a>
                   
                </div>      
            </div>
            <div class="col-6 p-0 bordenewsrow" style="display: none;">
                <a href="/assets/pdf/NERVA-2022-04-07.pdf" target="_blank">
                    <img src="/assets/images/Nerva_press-1.jpg" style="width: 100%; height: 10em;" alt="">
                </a>        
                <div>
                    <a href="/assets/pdf/NERVA-2022-04-07.pdf" target="_blank">
                        <h4 class="textocard" style="color:white; font-size:18px">{{'NERVA desvela los detalles de su nuevo e-scooter GT para uso urbano: EXE.'| translate}}</h4>
                        <p class="ml-5" style="color:white">2022-04-07</p>
        
                    </a>
                   
                </div>      
            </div>
            <div class="col-6 p-0 bordenewsrow" style="display: none;" >
                <a href="/assets/pdf/NERVA-2022-02-28.pdf" target="_blank">
                    <img src="/assets/images/noticia1.jpeg" style="width: 100%; height: 10em;" alt="">
                </a>        
                <div>
                    <a href="/assets/pdf/NERVA-2022-02-28.pdf" target="_blank">
                        <h4 class="textocard" style="color:white; font-size:18px">{{'Nace NERVA Mobility, la nueva marca española de movilidad eléctrica.'| translate}}
    
                        </h4>
                        <p class="ml-5" style="color:white">2022-02-28</p>
        
                    </a>
                </div>
        
            </div>
        </div>    
    </section>
    
    <section *ngIf="lang == 'it'">
        <div class="row">
            <div class="col-6 p-0 bordenewsrow" >
                <a href="https://emovingmag.it/2023/06/29/exe-il-maxi-scooter-del-brand-spagnolo-nerva-con-batterie-lfp-di-byd/" target="_blank">
                    <img src="/assets/images/noticiait3.webp" style="width: 100%; height: 10em;" alt="">
                </a>        
                <div>
                    <a href="https://emovingmag.it/2023/06/29/exe-il-maxi-scooter-del-brand-spagnolo-nerva-con-batterie-lfp-di-byd/" target="_blank">
                        <h4 class="textocard" style="color:white; font-size:18px">{{'EXE: IL MAXI SCOOTER DEL BRAND SPAGNOLO NERVA CON BATTERIE LFP DI BYD.'| translate}}</h4>
                        <p class="ml-5 mt-5" style="color:white">02-7-2023</p>
        
                    </a>
                </div>
        
            </div>
            <div class="col-6 p-0 bordenewsrow" >
                <a href="https://www.gazzetta.it/motori/la-mia-moto/prove-scooter/30-06-2023/nerva-exe-caratteristiche-prezzo-e-prova-dello-scooter-elettrico-spagnolo.shtml" target="_blank">
                    <img src="/assets/images/noticiait1.webp" style="width: 100%; height: 10em;" alt="">
                </a>        
                <div>
                    <a href="https://www.gazzetta.it/motori/la-mia-moto/prove-scooter/30-06-2023/nerva-exe-caratteristiche-prezzo-e-prova-dello-scooter-elettrico-spagnolo.shtml" target="_blank">
                        <h4 class="textocard" style="color:white; font-size:18px">{{'Nerva debutta in Italia con lo scooter elettrico GT Exe.'| translate}}
                        </h4>
                        <p class="ml-5 mt-5" style="color:white">30-6-2023</p>
        
                    </a>
                   
                </div>      
            </div>
            <div class="col-6 p-0 bordenewsrow" >
                <a href="https://www.moto.it/prove/nerva-exe-abbiamo-provato-lo-scooter-elettrico-gt-con-batterie-lfp.html" target="_blank">
                    <img src="/assets/images/Nerva_press-1.webp" style="width: 100%; height: 10em;" alt="">
                </a>        
                <div>
                    <a href="https://www.moto.it/prove/nerva-exe-abbiamo-provato-lo-scooter-elettrico-gt-con-batterie-lfp.html" target="_blank">
                        <h4 class="textocard" style="color:white; font-size:18px">{{'Nerva Exe: abbiamo provato lo scooter elettrico GT con batterie LFP.'| translate}}</h4>
                        <p class="ml-5 mt-5" style="color:white">30-6-2023</p>
        
                    </a>
                   
                </div>      
            </div>
            <div class="col-6 p-0 bordenewsrow" >
                <a href="https://www.motociclismo.it/prova-scooter-elettrico-nerva-exe-2023-83048" target="_blank">
                    <img src="/assets/images/noticiait2.webp" style="width: 100%; height: 10em;" alt="">
                </a>        
                <div>
                    <a href="https://www.motociclismo.it/prova-scooter-elettrico-nerva-exe-2023-83048" target="_blank">
                        <h4 class="textocard" style="color:white; font-size:18px">{{'TEST - Nerva EXE: lo scooterone elettrico da città che aiuta l\'Africa.'| translate}}</h4>
                        <p class="ml-5 mt-5" style="color:white">30-6-2023</p>
        
                    </a>
                </div>
        
            </div>
            <div class="col-6 p-0 bordenewsrow" >
                <a href="https://www.ansa.it/canale_motori/notizie/due_ruote/2023/06/28/nerva-sbarca-in-italia-con-lo-scooter-elettrico-exe_2270ba49-b637-4acc-a043-7338af4f0bbf.html#:~:text=La%20batteria%20riutilizzata%20in%20un%20programma%20umanitario%20in%20Africa&text=Arriva%20in%20Italia%20Exe%2C%20lo,durata%20e%20alta%20capacit%C3%A0%20specifica" target="_blank">
                    <img src="/assets/images/noticiait4.webp" style="width: 100%; height: 10em;" alt="">
                </a>        
                <div>
                    <a href="https://www.ansa.it/canale_motori/notizie/due_ruote/2023/06/28/nerva-sbarca-in-italia-con-lo-scooter-elettrico-exe_2270ba49-b637-4acc-a043-7338af4f0bbf.html#:~:text=La%20batteria%20riutilizzata%20in%20un%20programma%20umanitario%20in%20Africa&text=Arriva%20in%20Italia%20Exe%2C%20lo,durata%20e%20alta%20capacit%C3%A0%20specifica" target="_blank">
                        <h4 class="textocard" style="color:white; font-size:18px">{{'Nerva sbarca in Italia con lo scooter elettrico Exe.'| translate}}</h4>
                        <p class="ml-5 mt-5" style="color:white">28-6-2023</p>
        
                    </a>
                </div>
        
            </div>
            <div class="col-6 p-0 bordenewsrow" >
                <a href="https://www.motociclismo.it/prova-scooter-elettrico-nerva-exe-2023-83048" target="_blank">
                    <img src="/assets/images/noticia1.jpg" style="width: 100%; height: 10em;" alt="">
                </a>        
                <div>
                    <a href="https://www.motociclismo.it/prova-scooter-elettrico-nerva-exe-2023-83048" target="_blank">
                        <h4 class="textocard" style="color:white; font-size:18px">{{'Prova Nerva Exe, l´elettrico solidale.'| translate}}</h4>
                        <p class="ml-5 mt-5" style="color:white">02-6-2023</p>
        
                    </a>
                </div>
        
            </div>
    
    
    
        </div>    
  
    </section>
</div>


</body>




<app-footer2></app-footer2>
<app-modos-conduccion></app-modos-conduccion>
<app-compra-scooter></app-compra-scooter>
<app-alquila-scooter></app-alquila-scooter>
