import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GeoapiService } from 'src/app/services/geoapi.service';

@Component({
  selector: 'app-header2',
  templateUrl: './header2.component.html',
  styleUrls: ['./header2.component.css']
})
export class Header2Component implements OnInit {
  windowWidth: number;
  public lang: string;
  idioma: string;

  language: string;


 constructor(geoApiService: GeoapiService, private route: ActivatedRoute) {
  //   geoApiService.getIPAddress().then(
  //     (ipAddress => {
  //         geoApiService.getCountry(ipAddress).subscribe({
  //             next: (r: any) => {
  //                 this.lang = r.message?.country?.iso_code.toLowerCase();
  //                 console.log(this.lang)

  //             }
  //         })
  //     })
  //   )
}

  ngOnInit(): void {
    
    let leng = 'en';
    this.language = leng;
    console.log(leng);
    console.log(this.language);
    
    setTimeout(() => {
      this.route.queryParams.subscribe((params)=> {
        console.log(params);
        
        if(params['lang']){
          sessionStorage.setItem("lang", params['lang']);
          this.idioma = params['lang'];

        } else if (sessionStorage.getItem("lang")) {
          let lang = sessionStorage.getItem("lang")
          let language = lang;
          this.idioma = lang;

        } else {
          let lang = 'en';
          let language = lang;
          this.idioma = lang;
          console.log(language)
        }

      });
    }, 1)



  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = event.target.innerWidth; 
  }

}
