import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactoService } from '../../../services/contacto.service';
import { ConcesionariosService } from '../../../services/concesionarios.service';
@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})


export class ContactoComponent implements OnInit {
  
  public contactoForm: FormGroup;
  public loading: boolean = false;
  public exito: boolean = false;
  public submitted: boolean = false;
  public concesionarios: any [] = [];
  public provincias: any [] = [];
  public isLoading = true;

  constructor(private formBuilder: FormBuilder, private contactoService: ContactoService, private concesionariosService: ConcesionariosService) { }

  ngOnInit(): void {
    this.createForm();
    this.getConcesionarios();
    
  }

  getProvincias(concesionarios) {

    var provincias = [];
    concesionarios.forEach( function(concesionario, indice, array) {
      
      provincias.push(concesionario.city);

    });
    
    provincias = provincias.filter(function(item, pos) {
      return provincias.indexOf(item) == pos;
    })
    
    this.provincias = provincias.sort();

    console.log(this.provincias)
  }

  filtrar() {
    this.isLoading = true
    this.concesionariosService.getConsesionarios(this.f.provincia.value).subscribe({
      next: (r: any) => {
       this.concesionarios = r
       console.log(r)
       this.isLoading = false
      }
    })
  } 
  
  getConcesionarios() {
    this.concesionariosService.getConsesionarios(null).subscribe({
      next: (r: any) => {
       this.concesionarios = r
       this.getProvincias(this.concesionarios)
       this.isLoading = false
      }
    })
  }


  createForm() {
    this.contactoForm = this.formBuilder.group({
      motivo: ['', Validators.required],
      consulta: ['', Validators.required],
      nombre: ['', Validators.required],
      apellidos: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telefono: ['', Validators.required],
      pais: ['', Validators.required],
      cp: ['', Validators.required],
      profesional: ['0'],
      provincia: ['']
    })
  }

  get f() {
    return this.contactoForm.controls;
  }

  enviarConsulta() {
    this.submitted = true;
    if (this.contactoForm.invalid) {
      return;
    }
    
    this.loading = true;
    let description = 'Nombre completo: ' + this.f.nombre.value +' '+ this.f.apellidos.value +'<br/>' +
                      'Email: ' + this.f.email.value + '<br/>' +
                      'Phone: ' + this.f.telefono.value + '<br/>' +
                      'Country: ' + this.f.pais.value + '<br/>' +
                      'Postal code: ' + this.f.cp.value + '<br/>' +
                      'Motivo: ' + this.f.motivo.value + '<br/>' +
                      'Descripcion: ' + this.f.consulta.value;

    
    const contactoArray = {
      lead_name: this.f.nombre.value +' '+ this.f.apellidos.value,
      email_id: this.f.email.value,
      type: 'Consultant',
      notes: [  {note: description} ],
      pincode: this.f.cp.value,
      phone: this.f.telefono.value,
      country: this.f.pais.value,
      source: 'Web nerva.eco',
      profesional: this.f.profesional.value
    };
    console.log(contactoArray);

    this.contactoService.crearLead(contactoArray).subscribe({
      next: (r) => {
        this.loading = false;
        this.exito = true;
      },
      error: (e) => console.log(e)
    });
  }

}
